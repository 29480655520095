import { memo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import ColabCapaPrinc from "./ColabCapaPrinc";
import { dataColab, propStyleGrid } from "./data.js";
import MyLottie from "../MyLottie";
import animColaboradores from "../../Assets/animation/animColaboradores.json";
import animColaboradoresM from "../../Assets/animation/animColaboradoresM.json";
import descPrincColab from "../../Assets/animation/descPrincColab.json";
import MyAnimTextMain from "../MyAnimTextMain";
import { ContentBgColor, ContentPrinc, ContainerData, ContainerLottieDesc, GridItemColab } from "./styles";
import { ItensHtmlLottieDescColabPatroc, ItensHtmlLottieMainPatroc } from "../LottieHtml/data";

export const ImgBgColab = "https://d13x8u4oijb9vw.cloudfront.net/Colab-img.jpg";

export default memo(function GridColab(props) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const match = useRouteMatch();
  const history = useHistory();

  function onSetColabItem(e) {
    setTimeout(() => {
      history.push(`${match.path}/${e}`);
    }, 300);
  }

  return (
      <ContentPrinc theme={theme} >
        <section>
          <ItensHtmlLottieMainPatroc />
          <MyAnimTextMain title="" isMovie={false} anim={animColaboradores} animM={animColaboradoresM} img={ImgBgColab} />
        </section>
        <section>
          <ContentBgColor>
            <ContainerLottieDesc maxWidth="xs" >
              <ItensHtmlLottieDescColabPatroc />
              <MyLottie animation={descPrincColab} scroll />
            </ContainerLottieDesc>
            <ContainerData maxWidth="lg">
              <Grid {...propStyleGrid} spacing={isXs ? 0 : 2} >
                {dataColab.map((e, key) => {
                  return (
                    <GridItemColab
                      item
                      key={key}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <ColabCapaPrinc
                        count={key}
                        search={e.search}
                        master={e.master}
                        hexa={e.hexa}
                        title={e.nome + " " + e.sobreNome}
                        description={e.cargo}
                        image={e.img}
                        nome={e.nome}
                        setarColabItem={() => onSetColabItem(e.search)}
                      />
                    </GridItemColab>
                  );
                })}
              </Grid>
            </ContainerData>
          </ContentBgColor>
        </section>
      </ContentPrinc>
  );
});
