import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  border-radius: 9px;
  min-height: 650px;
  height: 650px;
`;

export const ContentSm = styled.div`
  display: flex;
  height: 450px;
  border-radius: 9px;  
`;

export const ContentPrinc = styled.div`  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ContentSec = styled.div``;

export const ContentFade = styled.div``;