import { CSSProperties } from "styled-components";

export const indicatorStyles: CSSProperties = {
    borderRadius: 9,
    width: 12,
    height: 12,
    display: 'inline-block',
    margin: '0 8px',
    cursor: 'pointer'
};

export const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
};