import { Box, useTheme } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { ContentFrameColab, IFrameStyle } from "./styles";
import ReactPlayer from "react-player";
import { IMyFrameColab } from "./interface";
import { transition } from "./data";

export default function MyFrameColab({ url, muted, animOpIntro }: IMyFrameColab) {
    const animContent = useAnimation();
    const theme = useTheme();

    useEffect(() => {
        if (animOpIntro) animO(1)
    }, []);

    const onReady = () => animO(1);
    const animO = (AOp: number) => animContent.start({ opacity: AOp });

    return (
        <ContentFrameColab theme={theme}>
            <motion.div
                style={{ height: 'inherit', width: 'inherit' }}
                initial={{ opacity: 0 }}
                animate={animContent}
                transition={transition}
            >
                <ReactPlayer
                    theme={theme}
                    playing={true}
                    loop
                    controls={true}
                    muted={muted}
                    style={{ borderRadius: 9 }}
                    playsinline
                    height={'inherit'}
                    width={'inherit'}
                    onProgress={(e) => ((e.playedSeconds > 0) && (e.playedSeconds < 1)) ? onReady() : false}
                    onError={() => animO(1)}
                    url={url}
                />
            </motion.div>
        </ContentFrameColab>
    )

}