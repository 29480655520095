import { Link } from "@material-ui/core";
import TextCenter from "../TextCenter";
import MyPressMotion from "../../MyPressMotion";
import MyFadeUp from "../../MyFadeUp";
import MyImageLazzy from "../../MyImageLazzy";
import { CardActionAreaComp, ContainerPrinc, ContentImg } from "./styles";

export default function ImageText(props) {
  return (
    <ContainerPrinc>
      <TextCenter
        isSticky
        title={props.title}
        desc={props.desc}
        padding="0px 0px 28px 0px"
      />
      <MyFadeUp fade>
        <MyPressMotion onClick={() => { }} >
          <CardActionAreaComp>
            <ContentImg>
              <Link target="_blank" href={"https://www.facebook.com/TVSudoesteParana/?locale=pt_BR"} >
                <MyImageLazzy
                  title=""
                  borderRadius={9}
                  backgrondLinear="rgb(0, 0, 0, 0), rgb(0 0 0 / 5%), rgb(0 0 0 / 10%), rgb(0 0 0 / 15%), rgb(0, 0, 0, 20%))"
                  isEquipe
                  width="100%"
                  image={props.img}
                />
              </Link>
            </ContentImg>
          </CardActionAreaComp>
        </MyPressMotion>
      </MyFadeUp>
    </ContainerPrinc>
  );
}
