import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./styles.css";

import MyFadeUp from "../../MyFadeUp";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { Waypoint } from "react-waypoint";
import CardCrSel from "../CardCrSel";

import { data } from "./data.js";
import { IconButton, useTheme } from "@material-ui/core";

import { arrowStyles, indicatorStyles } from "./styles";

// touchAction: 'pan-y'

export default function CrSelMaterial() {
  const theme = useTheme();
  const [onIndex, setOnIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);

  const handleEnter = () => {
    setAutoPlay(true);
  };

  const handleLeave = () => {
    setAutoPlay(false);
  };

  return (
    <Waypoint
      onEnter={handleEnter}
      onLeave={handleLeave}
    >
      <div>
        <MyFadeUp fade >

          <Carousel
            className="carouselMaterialClass"
            autoFocus={false}
            autoPlay={autoPlay}
            transitionTime={300}
            interval={3000}
            // autoFocus

            onClickItem={() => console.log(onIndex)}
            selectedItem={onIndex}
            onChange={(index, intem) => setOnIndex(index)}
            emulateTouch
            showIndicators
            // dynamicHeight
            showThumbs={false}
            showStatus={false}
            swipeable            
            // autoPlay
            infiniteLoop
            showArrows={false}
            // useKeyboardArrows
            stopOnHover

            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div onClick={onClickHandler} style={{ ...arrowStyles, left: 0 }}>
                  <IconButton color="primary" size="small" children={<ArrowLeft style={{ color: "rgb(251, 217, 67)" }} />} />
                </div>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div onClick={onClickHandler} style={{ ...arrowStyles, right: 0 }}>
                  <IconButton color="primary" size="small" children={<ArrowRight style={{ color: "rgb(251, 217, 67)" }} />} />
                </div>
              )
            }

            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: "rgb(251, 217, 67)" }}
                  />
                );
              }
              return (
                <li
                  style={{ ...indicatorStyles, background: 'rgb(251, 217, 67)', opacity: 0.3 }}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                />
              );
            }}

          // centerMode
          // centerSlidePercentage={90}
          // infiniteLoop={!isSmall}
          >
            {data.map((e, count) => {
              return (
                <div style={{ userSelect: "none" }} key={count}>
                  <CardCrSel
                    {...e}
                    count={count}
                  />
                </div>
              )
            })}
          </Carousel>
        </MyFadeUp >
      </div>
    </Waypoint>


  );
}
