import { Box, Container } from "@material-ui/core";
import styled from "styled-components";

export const ContentDescNossoPrograma = styled(Container)`
  padding-bottom: 0;
  padding-top: 40px;
`;

export const ContentCardInformation = styled(Container)`
  padding-bottom: 160px;
`;

export const ContentBoxCardInformation = styled(Box)`
  padding-bottom: 160px;
`;

export const ContainerLottieM = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: 600
    }
}));