import React from "react";
import { useTheme } from "@material-ui/core";
import MyLottie from "../MyLottie";
import { IMyLottieImage } from "./interface";
import { BoxPrinc } from "./styles";
import MyScrollAnim from "../MyScrollAnim";
import { LottiePlayerProvider } from "../../Contexts/LottiePlayer";
import MyMediaIntro from "./MyMediaIntro";
import { ContentLottie, ContentNoMaxWidth, ContentMaxWidth, ContentBgColor } from "./styles";

export default function MyLottieImage({ anim, img, maxwidth, isMovie, title }: IMyLottieImage) {
    const theme = useTheme();
    return (
        <LottiePlayerProvider isMovie={isMovie} >
            <ContentBgColor theme={theme}>
                <BoxPrinc>
                    <MyMediaIntro title={title} img={img} />
                    <ContentLottie>
                        {maxwidth === false
                            ?
                            <ContentNoMaxWidth>
                                <MyLottie animation={anim} loop={false} />
                            </ContentNoMaxWidth>
                            :
                            <ContentMaxWidth>
                                <MyLottie animation={anim} loop={false} />
                            </ContentMaxWidth>
                        }
                        <MyScrollAnim />
                    </ContentLottie>
                </BoxPrinc>
            </ContentBgColor>
        </LottiePlayerProvider>
    );
}
