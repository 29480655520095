
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import dataEquipe from "./data.js";
import EquipeCapa from "./EquipeCapa";
import MyLottie from "../MyLottie";

import animEquipe from "../../Assets/animation/animEquipe.json";
import animEquipeM from "../../Assets/animation/animEquipeM.json";

import descPrincEquipeCapa from "../../Assets/animation/descPrincEquipeCapa.json";
import MyAnimTextMain from "../MyAnimTextMain";
import { ContentRelative, styleGrid, ContentDescLottie } from "./styles";
import { ItensHtmlLottieEquipeCapaEquipe, ItensHtmlLottieMainEquipe } from "../LottieHtml/data.tsx";

export const ImgEquipeBdr = "https://d13x8u4oijb9vw.cloudfront.net/Equipe-img.jpg";

export default function GridEquipe(props) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ContentRelative theme={theme} >
      <section>
        <ItensHtmlLottieMainEquipe />
        <MyAnimTextMain title="" isMovie={false} anim={animEquipe} animM={animEquipeM} img={ImgEquipeBdr} />
      </section>
      <section>
        <Container maxWidth="lg">
          <ContentDescLottie maxWidth="xs">
            <ItensHtmlLottieEquipeCapaEquipe />
            <MyLottie animation={descPrincEquipeCapa} scroll />
          </ContentDescLottie>
          <Grid style={styleGrid} spacing={isXs ? 0 : 2} container >
            {dataEquipe.map((e, count) => {
              return (
                <Grid key={count} item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <EquipeCapa fontFamily={props.fontFamily} {...e} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </section>
    </ContentRelative>
  );
}
