import { Container } from "@material-ui/core";
import styled from "styled-components";

export const ContentDescAlign = styled.div(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
        // textAlign: "left",
    }
}));

export const ContainerMw = styled(Container)`
    max-width: 1100px;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
`;

export const ContentSec = styled.div(({ theme }) => ({
    padding: "40px 0px 23px 0px",
    maxWidth: 650,
    [theme.breakpoints.down('md')]: {
        padding: "40px 0px 103px 0px",
    }
}));
