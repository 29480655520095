import React from "react";
import { useTheme } from "@material-ui/core";
import IProps from "./interface";
import { ContentDesc, ContentPrinc, ContentSec, ContentSocialInfo, DescCargo, DescName } from "./styles";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";

export default function CardCrSel(props: IProps) {
  const theme = useTheme();
  return (
    <ContentPrinc key={props.count}>
      <ContentSec>
        <ContentDesc
          theme={theme}
          children={
            <MyTypographyDesc
              fontFamily="light"
              component="p"
              fontxs={theme.typography.body1.fontSize.toString()}
              fontsm={theme.typography.h6.fontSize.toString()}
              fontmd={theme.typography.h6.fontSize.toString()}
              fontlg={theme.typography.h6.fontSize.toString()}
              fontxl={theme.typography.h6.fontSize.toString()}
              children={props.desc}
            />
          }
        />
        <ContentSocialInfo>
          <DescName children={
            <MyTypographyDesc
              fontFamily="regular"
              component="h3"
              style={{ color: theme.palette.primary.light, letterSpacing: 1 }}
              fontxs={theme.typography.body1.fontSize.toString()}
              fontsm={theme.typography.h6.fontSize.toString()}
              fontmd={theme.typography.h6.fontSize.toString()}
              fontlg={theme.typography.h6.fontSize.toString()}
              fontxl={theme.typography.h6.fontSize.toString()}
              children={props.name}
            />
          }
          />
          <DescCargo children={
            <MyTypographyDesc
              fontFamily="light"
              component="p"
              style={{ letterSpacing: 1, opacity: .9 }}
              fontxs={theme.typography.body2.fontSize.toString()}
              fontsm={theme.typography.body2.fontSize.toString()}
              fontmd={theme.typography.body2.fontSize.toString()}
              fontlg={theme.typography.body2.fontSize.toString()}
              fontxl={theme.typography.body2.fontSize.toString()}
              children={props.cargo}
            />
          }
          />
        </ContentSocialInfo>
      </ContentSec>
    </ContentPrinc>
  );
}
