import styled from "styled-components";
import { motion } from "framer-motion"
import { IContentMotion } from "./interface";

export const ContentMotion = styled(motion.div)<IContentMotion>(({ img }) => ({
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 20%), rgb(0 0 0 / 38%), rgb(0 0 0 / 47%), rgba(0, 0, 0, 0.6)), url(${img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden',
}));
