import { Box, Container, useTheme } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MyFrameColab from '../GridColab/ColabItem/MyFrameColab';
import MyTypographyDesc from '../MyTypography/MyTypographyDesc';
import { returnImgResolution } from '../GridMater/MateriasItem/Materias/utils';
import { IGetMateriasYou, IMovie, IParamsMateriasYou } from './interface';
import { Content, ContentBg, ContentDesc, ContentDescPrinc, ContentMovie, ContentTitle } from './styles';
import { Helmet } from 'react-helmet-async';
import { ldJsonOrganization } from '../MyHelmet';

// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import LikeIcon from '@material-ui/icons/ThumbUp';
// import CommentIcon from '@material-ui/icons/ModeComment';


// const LightTooltip = withStyles((theme: Theme) => ({
//     tooltip: {
//         backgroundColor: theme.palette.common.white,
//         color: theme.palette.background.paper,
//         boxShadow: theme.shadows[1],
//         fontSize: theme.typography.body1.fontSize.toString(),
//     },
// }))(Tooltip);

export default function MateriasYou() {
    const { pathname } = useLocation();
    const linkWebsite = process.env.REACT_APP_LINK_WEBSITE + pathname;

    const [load, setLoad] = useState(true);
    const [movie, setMovie] = useState<IMovie | undefined>(undefined);
    const { id } = useParams<IParamsMateriasYou>();
    const history = useHistory();
    const theme = useTheme();

    useEffect(() => {
        handleCreate();
    }, []);

    const handleCreate = async () => {
        const body = JSON.stringify({ id: id });
        setLoad(true);
        await axios.post<IGetMateriasYou>(getLink(), body, { responseEncoding: "base64" }).then((data) => {
            const items = data.data.items;
            if (items === undefined || items.length === 0) {
                history.push("/materias");
            } else {
                const items = data.data.items[0];
                const snippet = items.snippet;
                const statistics = items.statistics;
                setMovie({
                    description: snippet.description,
                    img: returnImgResolution(snippet.thumbnails),
                    title: snippet.title,
                    videoId: id,
                    publishedAt: snippet.publishedAt,
                    ...statistics
                })
                setLoad(false);
            }
        }).catch((err) => console.log(err));
    }

    const getLink = (): string => "https://bxricytx6hpewpkysngxq7rkvi0lvuzt.lambda-url.sa-east-1.on.aws";

    const ldJsonBreadcrumbSubDomain = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": "1",
                "item": {
                    "@id": process.env.REACT_APP_LINK_WEBSITE + "/materias",
                    "type": "WebPage",
                    "url": process.env.REACT_APP_LINK_WEBSITE + "/materias",
                    "name": "Matérias"
                }
            },
            {
                "@type": "ListItem",
                "position": "2",
                "name": movie?.title
            }
        ]
    }

    const ldJsonVideoObject = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": movie?.title,
        "description": movie?.description,
        "thumbnailUrl": [
            movie?.img
        ],
        "uploadDate": movie?.publishedAt,
        "contentUrl": "https://youtube.com/watch/?v=".concat(movie?.videoId),
        "embedUrl": linkWebsite,
    }

    return (
        <>
            <Helmet>
                <title>{movie?.title}</title>
                <meta name="description" content={movie?.description} />
                <link rel="canonical" href={linkWebsite} />

                <script type="application/ld+json">
                    {JSON.stringify(ldJsonOrganization)}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify(ldJsonBreadcrumbSubDomain)}
                </script>

                <meta property="og:title" content={movie?.title} />
                <meta property="og:description" content={movie?.description} />
                <meta property="og:url" content={linkWebsite} />
                
                <meta name="twitter:title" content={movie?.title} />
                <meta name="twitter:description" content={movie?.description} />

                <script type="application/ld+json">
                    {JSON.stringify(ldJsonVideoObject)}
                </script>

            </Helmet>
            <ContentBg theme={theme} >
                <Container maxWidth="lg">
                    <Content theme={theme} >
                        <ContentMovie theme={theme} >
                            <MyFrameColab
                                animOpIntro
                                muted={false}
                                url={load ? "" : "https://www.youtube.com/embed/" + id}
                            />
                        </ContentMovie>
                        <ContentDescPrinc>
                            <ContentTitle theme={theme} style={{ backgroundColor: load ? "#ffffff1a" : "transparent" }} >
                                <MyTypographyDesc
                                    fade
                                    fontFamily="semi-bold"
                                    component="h1"
                                    color="primary"
                                    fontxs={'1.60rem'}
                                    fontsm={'1.90rem'}
                                    fontmd={'1.90rem'}
                                    fontlg={'1.90rem'}
                                    fontxl={'1.90rem'}
                                    style={{
                                        color: theme.palette.primary.main,
                                        textAlign: "left"
                                    }}
                                    children={load ? "" : movie?.title}
                                />
                            </ContentTitle>

                            {/* <Box style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} >
                            <LightTooltip title="Visualizações" aria-label="Visualizações">
                                <Box style={{ height: 25, paddingLeft: 5, paddingRight: 15, display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                    <PlayArrowIcon style={{ height: 25, width: 25, paddingRight: 7 }} color="primary" />
                                    <MyTypographyDesc
                                        fade
                                        fontFamily="light"
                                        component="h2"
                                        color="primary"
                                        fontxs={typography.body1.fontSize.toString()}
                                        fontsm={typography.body1.fontSize.toString()}
                                        fontmd={typography.body1.fontSize.toString()}
                                        fontlg={typography.body1.fontSize.toString()}
                                        fontxl={typography.body1.fontSize.toString()}
                                        style={{
                                            color: palette.primary.main,
                                            textAlign: "center",
                                            whiteSpace: "break-spaces"
                                        }}
                                        children={parseInt(movie?.viewCount).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                                    />
                                </Box>
                            </LightTooltip>

                            <LightTooltip title="Curtidas" aria-label="Curtidas">
                                <Box style={{ height: 25, paddingLeft: 5, paddingRight: 10, display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                    <LikeIcon style={{ height: 20, width: 20, paddingRight: 7 }} color="primary" />
                                    <MyTypographyDesc
                                        fade
                                        fontFamily="light"
                                        component="h2"
                                        color="primary"
                                        fontxs={typography.body1.fontSize.toString()}
                                        fontsm={typography.body1.fontSize.toString()}
                                        fontmd={typography.body1.fontSize.toString()}
                                        fontlg={typography.body1.fontSize.toString()}
                                        fontxl={typography.body1.fontSize.toString()}
                                        style={{
                                            color: palette.primary.main,
                                            textAlign: "center",
                                            whiteSpace: "break-spaces"
                                        }}
                                        children={parseInt(movie?.likeCount).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                                    />
                                </Box>
                            </LightTooltip>

                            <LightTooltip title="Comentários" aria-label="Comentários">
                                <Box style={{ height: 25, paddingLeft: 5, paddingRight: 5, display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                    <CommentIcon style={{ height: 18, width: 18, paddingRight: 7 }} color="primary" />
                                    <MyTypographyDesc
                                        fade
                                        fontFamily="light"
                                        component="h2"
                                        color="primary"
                                        fontxs={typography.body1.fontSize.toString()}
                                        fontsm={typography.body1.fontSize.toString()}
                                        fontmd={typography.body1.fontSize.toString()}
                                        fontlg={typography.body1.fontSize.toString()}
                                        fontxl={typography.body1.fontSize.toString()}
                                        style={{
                                            color: palette.primary.main,
                                            textAlign: "center",
                                            whiteSpace: "break-spaces"
                                        }}
                                        children={parseInt(movie?.commentCount).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                                    />
                                </Box>
                            </LightTooltip>
                        </Box> */}
                            <ContentDesc>
                                <MyTypographyDesc
                                    fade
                                    fontFamily="light"
                                    component="h2"
                                    color="primary"
                                    fontxs={theme.typography.body1.fontSize.toString()}
                                    fontsm={theme.typography.body1.fontSize.toString()}
                                    fontmd={theme.typography.body1.fontSize.toString()}
                                    fontlg={theme.typography.body1.fontSize.toString()}
                                    fontxl={theme.typography.h6.fontSize.toString()}
                                    style={{
                                        color: theme.palette.primary.main,
                                        textAlign: "left",
                                        whiteSpace: "break-spaces"
                                    }}
                                    children={movie?.description}
                                />
                            </ContentDesc>
                        </ContentDescPrinc>
                    </Content>
                </Container>
            </ContentBg>
        </>
    )
}