import { Container } from "@material-ui/core";
import styled from "styled-components";

export const ContentBgColor = styled.main`
  min-height: 200vh;
  background: ${props => props.bgcolor};
  padding-bottom: 60px;
`;

export const ContentPadding = styled.div`
  padding: 0px 0px 103px 0px;
`;

export const ContainerLottieDesc = styled(Container)(({ theme }) => ({
  paddingBottom: 30,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 400
  }
}));