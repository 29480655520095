import { CardActionArea } from "@material-ui/core";
import { motion } from "framer-motion";
import styled from "styled-components";

export const CardComponent = styled(motion.div)`
  height: 350;
  width: "100%";
  border-radius: 9px;
`;

export const ContentPrinc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border-radius: 9px;
  padding: 0px;
`;

export const ContentBgColor = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: 350,
    borderRadius: 9,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
        maxWidth: 650
    }
}));

export const CardActionAreaComp = styled(CardActionArea)`
  color: #fff;  
`;

export const ContentSec = styled(motion.div)`
  height: inherit;
  width: inherit;
  overflow: hidden;
  border-radius: 4px;
`;

export const ContentDescCard = styled(motion.div)`
`;