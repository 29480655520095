import React from 'react';
import Materias from "./Materias";
import { playListMaterData } from "../dataPlayList";
import { Box, useTheme } from '@material-ui/core';
import MyTypographyDesc from '../../MyTypography/MyTypographyDesc';
import { ContentPadding } from './styles';

export default function MateriasItem() {
    const theme = useTheme();

    return (
        <section>
            {playListMaterData.map((data, key) => (
                <Box style={{ paddingBottom: 40 }} key={key} >
                    <ContentPadding theme={theme}>
                        <MyTypographyDesc
                            fontFamily="l-bold"
                            component="h2"
                            color="primary"
                            style={{ color: theme.palette.primary.light, letterSpacing: 1 }}
                            fontxs={theme.typography.h5.fontSize.toString()}
                            fontsm={theme.typography.h5.fontSize.toString()}
                            fontmd={theme.typography.h5.fontSize.toString()}
                            fontlg={theme.typography.h5.fontSize.toString()}
                            fontxl={theme.typography.h5.fontSize.toString()}
                            children={data.title}
                        />
                    </ContentPadding>
                    <Materias title={data.title} link={data.link} />
                </Box>
            ))}
        </section>
    )
}