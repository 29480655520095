import React from 'react';
import { IMyLink } from './interface';
import { ContentComp, LinkComp } from "./styles";

export default function MyLink({ target, href, children, disabled, title }: IMyLink) {
    return (
        disabled
            ?
            <ContentComp
                children={children}
            />
            :
            <LinkComp
                style={{ textDecoration: "none" }}
                onClick={(e) => e.preventDefault()}
                href={href}
                type='image/png'
                target={target}
                children={children}
                title={title}
            />
    )
}