import { IPage } from './interface';

export function returnBgColor(APage: string, onPage: IPage, ABgColor: string, ABgColorNoSelect?: string): string {                
        if ((APage === 'Início') && (onPage.inicio)) {
                return ABgColor;
        } else if ((APage === 'Matérias') && (onPage.materias)) {
                return ABgColor
        } else if ((APage === 'Patrocinadores') && (onPage.patrocinadores)) {
                return ABgColor
        } else if ((APage === 'Equipe') && (onPage.equipe)) {
                return ABgColor
        } else if ((APage === 'Sobre') && (onPage.sobre)) {
                return ABgColor
        } 
        else {
                return ABgColorNoSelect || ''
        }
}