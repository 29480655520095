import React from "react";
import { Button } from "@material-ui/core";
import { IMyLogo } from "./interface";
import MyImageLazzy from "../MyImageLazzy";
import MyLink from "../MyLink";

export default function MyLogo({ setarRouter, logo }: IMyLogo) {
    return (
        <Button style={{ zIndex: 3, userSelect: 'none' }} color="primary" onClick={() => setarRouter("/")} >
            <MyLink title="" href={"/"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <MyImageLazzy
                        title=""
                        disableBackgroundLinear
                        backgroundColor="transparent"
                        height={'40px'}
                        width={'140px'}
                        image={logo}
                    />
                </div>
            </MyLink>
        </Button>
    )
}