import styled from "styled-components";
import { Link } from "@material-ui/core";

export const ContentComp = styled.div`
    height: 100%;
    width: 100%;
`;

export const LinkComp = styled(Link)`
    height: 100%;
    width: 100%;
`;