import React, { useEffect, useState } from "react";
import MyLottieScroll from "./MyLottieScroll";
import { IMyScrollAnim } from "./interface";
import { useLottiePlayer } from "../../Contexts/LottiePlayer";

export default function MyScrollAnim({ }: IMyScrollAnim) {
    const { openLottie } = useLottiePlayer();
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(openLottie)
        // const timeout = setTimeout(() => setShow(openLottie), 1000);
        // return () => clearTimeout(timeout);
    }, [openLottie]);

    // const routeValid = () => {
    //     const routes = ["/materias", "/colaboradores", "/colaboradores", "/equipe", "/sobre"]
    //     return pathname in routes;
    // }

    // const setTimeDelay = (): number => {
    //     switch (pathname) {
    //         case '/':
    //             return 0 //5500 + 700
    //         default:
    //             return 3000
    //     }
    // }

    // // useEffect(() => {
    //     const timeout = setTimeout(() => handleSetShow(), 0/*setTimeDelay()*/);
    //     return () => clearTimeout(timeout);
    // }, [openLottie])

    // const handleSetShow = () => {        
    //     if (routeValid()) {            
    //         setShow(openLottie);
    //     } else if (pathname === '/') {            
    //         if (isMovieDesk || isGif || isImageDesk) {
    //             setShow(true)
    //         }
    //     } else {
    //         setShow(openLottie);
    //     }
    // }

    // function isVisible(): boolean {
    //     if (routeValid) {
    //         if (isMovieDesk) {
    //             return openLottie
    //         } else {
    //             return true;
    //         }
    //     } else {
    //         return false;
    //     }
    // }

    // return routeValid && <MyLottieScroll timeDelay={setTimeDelay()} />
    return show && <MyLottieScroll />

}