import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";
import ReactRouter from "./Router";

import Aos from "aos";
import "./styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { theme } from "./theme";
import { HelmetProvider } from "react-helmet-async";

export default function App() {

  useEffect(() => {
    document.addEventListener('touchstart', () => { }, { passive: true });
    document.addEventListener('touchmove', () => { }, { passive: true });
    document.addEventListener('mousewheel', () => { }, { passive: true });

    Aos.init({ once: true });
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <ReactRouter />
      </ThemeProvider>
    </HelmetProvider>
  );
}
