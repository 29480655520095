import styled from "styled-components";

export const ContentMap = styled.div(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.down('xs')]: {
        paddingBottom: 16,
        paddingLeft: 0,
        paddingRight: 0
    }
}));

export const ContentMwMap = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: 500,
    borderRadius: 9,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
        maxWidth: 650
    }
}));

export const IFrameGoogleMaps = styled.iframe(({ }) => ({
    border: 0,
    height: 500,
    width: "100%",
    borderRadius: 9,
    filter: 'brightness(85%)'
}));
