import { motion } from "framer-motion";
import styled from "styled-components";

export const ContentAnim = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // zIndex: 10000000
}));
