import { motion } from "framer-motion";
import { CardActionArea } from "@material-ui/core";
import styled from "styled-components";

export const ContentMh = styled.div(({ theme }) => ({ 
  [theme.breakpoints.down('md')]: {
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  // [theme.breakpoints.up('md')]: {
  //   minHeight: 250,
  // },
  [theme.breakpoints.up('lg')]: {
    minHeight: 200,
  },
}));

export const ContentPrinc = styled(motion.div)`
  height: 100%;
  width: 100%;
  border-radius: 9px;
  z-index: 10;
`;

export const CardActionAreaComp = styled(CardActionArea)(({ theme }) => ({
  color: theme.palette.primary.main,

  height: "100%",
  borderRadius: 'inherit',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: 15,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    paddingLeft: 2,
    paddingRight: 2
  }
}));

export const ContentFlex = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;  
`;

export const ContentDesc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 15px;
`;

export const ContentDescMq = styled.div(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down('xs')]: {
    maxWidth: 350,
    // paddingLeft: 5,
    // paddingRight: 5
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 500
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 500
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 500
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 500
  }
}));