import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core"
import ImageTextSticky from "../ImageTextSticky";
import ImageText from "../ImageText";
import { IStickyComp } from "./interface";

export default function StickyComp({ title, desc, img }: IStickyComp) {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        isSm
            ?
            <ImageText
                title={title}
                desc={desc}
                img={img}
            />
            :
            <ImageTextSticky
                title={title}
                desc={desc}
                img={img}
            />

    )
}