import styled from "styled-components";

export const ContentBgColor = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
    }
}));

export const ContentMediaQ = styled.div(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '100%'
    }
}));

export const CardMediaComp = styled.div(({ theme }) => ({
    minHeight: 100,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
        height: 400,
    },
    [theme.breakpoints.up('sm')]: {
        height: 450,
    },
    [theme.breakpoints.up('md')]: {
        height: 500,
    },
    [theme.breakpoints.up('lg')]: {
        height: 550,
    },
}));

/**/
export const BoxPrinc = styled.div(({ theme }) => ({
    display: "flex",
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        height: "100%"
    }
}));

export const ContentDesc = styled.div(({ theme }) => ({
    marginBottom: 8,
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 3,
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
        maxWidth: '100%',
    },
}))

export const ContentDescription = styled.div(({ theme }) => ({
    paddingRight: 10,
    maxWidth: 550,
    [theme.breakpoints.down("sm")]: {
        maxWidth: '100%',
        paddingRight: '0px',
        paddingBottom: 18
    },
}))

export const BoxImage = styled.div(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center"
    }
}));