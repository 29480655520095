import { Box, styled, AppBar, IconButton, Typography } from "@material-ui/core";

export const StackComp = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    opacity: 1,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)",
    [theme.breakpoints.down("sm")]: {
        opacity: 0,
        right: 24,
        position: "absolute",
    },
}));

export const IconButtonMenu = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: 0,
    marginRight: 16,
    opacity: 0,
    transition: "ease-in-out .3s",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
        opacity: 1,
    }
}));

export const AppBarComp = styled(AppBar)(({ theme }) => ({
    background: "rgba(15, 15, 15, 0.98)",
    // background: 'linear-gradient(rgba(0, 0, 0, 30%), rgba(0, 0, 0, 30%), rgb(0 0 0 / 30%), rgb(0 0 0 / 30%), rgb(0 0 0 / 30%), rgb(0, 0, 0, 30%), rgb(0, 0, 0, 30%))'
}));
