const C_DESC_FIRST = "Ser a melhor mídia do Agro do Sudoeste.";
const C_DESC_SEC = "Captar e divulgar a grande diversidade e a riqueza da agropecuária do Sudoeste do Paraná e levar informação e instrução ao homem do campo.";
const C_DESC_THIRD = "Responsabilidade Social, ética, imparcialidade, respeito irrestrito, confiabilidade, compromisso com a verdade e qualidade de vídeo e áudio.";

export default [
    {
        titleFirst: "Nossa",
        titleSec: "MISSÃO",
        desc: C_DESC_SEC,
        position: 1,
    },
    {
        titleFirst: "Nossa",
        titleSec: "VISÃO",
        desc: C_DESC_FIRST,
        position: 2,
    },
    {
        titleFirst: "Nossos",
        titleSec: "VALORES",
        desc: C_DESC_THIRD,
        position: 3
    }
];