import { ILoadImage } from "./interface";

export const transitionMotion = {
    ease: [.6, .6, .6, .6],
    duration: .1
}

export const spacingElements = 16;

export const brightnessImageIntro = 57;

export const loadImage = ({ onLoad, src }: ILoadImage) => {
    const bgImg = new Image();
    bgImg.onload = async () => onLoad;
    bgImg.src = src;
}
