import React from "react";
import { useTheme } from "@material-ui/core";
import ICardItem from "./interface";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import { CardActionAreaComp, ContentFlex, ContentPrinc, ContentDesc, ContentDescMq, ContentMh } from "./styles";

export default function CardItem(props: ICardItem) {
  const theme = useTheme();

  const handleHover = () => props.handleHover(props.position);

  return (
    <ContentPrinc
      onHoverStart={handleHover}
    >
      <CardActionAreaComp theme={theme} disableRipple disableTouchRipple >
        <ContentFlex>
          <ContentMh theme={theme}>
            <MyTypographyDesc
              fontFamily="bold"
              component="h2"
              style={{ letterSpacing: 1, color: theme.palette.primary.light }}
              fontxs={theme.typography.h5.fontSize.toString()}
              fontsm={theme.typography.h4.fontSize.toString()}
              fontmd={theme.typography.h4.fontSize.toString()}
              fontlg={theme.typography.h4.fontSize.toString()}
              fontxl={theme.typography.h4.fontSize.toString()}
              children={props.titleSec}
            />
            <ContentDesc>
              <ContentDescMq theme={theme}>
                <MyTypographyDesc
                  fontFamily="light"
                  component="p"
                  fontxs={theme.typography.body1.fontSize.toString()}
                  fontsm={theme.typography.h6.fontSize.toString()}
                  fontmd={theme.typography.h6.fontSize.toString()}
                  fontlg={theme.typography.h6.fontSize.toString()}
                  fontxl={theme.typography.h6.fontSize.toString()}
                  children={props.desc}
                />
              </ContentDescMq>
            </ContentDesc>
          </ContentMh>
        </ContentFlex>
      </CardActionAreaComp>
    </ContentPrinc>
  );
}
