import styled from "styled-components";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";

export const ContentPrinc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;  
  text-align: center;
  align-items: center;
  justify-content: center;    
  padding: ${props => props.padding}; 
`;

export const TypographySticky = styled(MyTypographyDesc)(({ theme }) => ({

}));