import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const ContentPrinc = styled.div`  
  display: flex;
  justify-content: center;
  flex-direction: column;    
  background-color: transparent;  
  min-height: 200px;
`;

export const GridPrinc = styled(Grid)`
  display: flex;
  text-align: center;
  width: 100%;
  margin: 0
`