import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, useTheme } from "@material-ui/core";
import { CardComp, ContentAvatar, ContentAvatarPrinc, ContentCircle, ContentDesc, ContentItem, ContentSocial, FacebookIconComp, InstagramIconComp } from "./styles";
import IProps from "./interface";
import MyPressMotion from "../../MyPressMotion";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import MyFadeUp from "../../MyFadeUp";
import MyImageLazzy from "../../MyImageLazzy";
import MyLink from "../../MyLink";

export default function EquipeCapa(props: IProps) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const linkHref = location.pathname + "/" + props.search;

  function setarPath() {
    setTimeout(() => {
      history.push(linkHref);
    }, 300);
  }

  return (
    <MyPressMotion onClick={() => setarPath()} >
      <MyLink href={linkHref}>
        <MyFadeUp fade >
          <CardComp>
            <ContentItem>
              <ContentCircle />
              <ContentAvatarPrinc>
                <ContentAvatar>
                  <Box style={{ borderRadius: "50%" }} >
                    <MyImageLazzy
                      title={props.nome + " " + props.sobreNome}
                      disableBackgroundLinear
                      height="150px"
                      width="150px"
                      borderRadius="50%"
                      image={props.img}
                    />
                  </Box>
                </ContentAvatar>
              </ContentAvatarPrinc>
              <ContentDesc>
                <MyTypographyDesc
                  fontFamily="semi-bold"
                  component="h3"
                  style={{ color: theme.palette.secondary.dark, letterSpacing: .9, opacity: 0.8 }}
                  fontxs={theme.typography.h5.fontSize.toString()}
                  fontsm={theme.typography.h5.fontSize.toString()}
                  fontmd={theme.typography.h5.fontSize.toString()}
                  fontlg={theme.typography.h5.fontSize.toString()}
                  fontxl={theme.typography.h5.fontSize.toString()}
                  children={props.nome + " " + props.sobreNome}
                />
                <MyTypographyDesc
                  fontFamily="regular"
                  component="p"
                  style={{ color: theme.palette.secondary.dark, opacity: .8, letterSpacing: 1 }}
                  fontxs={theme.typography.body1.fontSize.toString()}
                  fontsm={theme.typography.body1.fontSize.toString()}
                  fontmd={theme.typography.body1.fontSize.toString()}
                  fontlg={theme.typography.body1.fontSize.toString()}
                  fontxl={theme.typography.body1.fontSize.toString()}
                  children={props.cargo}
                />
              </ContentDesc>
              <ContentSocial
                children={
                  <>
                    <FacebookIconComp />
                    <InstagramIconComp />
                  </>
                }
              />
            </ContentItem>
          </CardComp>
        </MyFadeUp>
      </MyLink>
    </MyPressMotion >
  );
}
