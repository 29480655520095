import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { IMyLottie } from "./interface";
import { useLottiePlayer } from "../../Contexts/LottiePlayer";
import { interactivity } from "./data";

export default function MyLottie({ animation, scroll, loop, height, width }: IMyLottie) {
  const { openLottie, isMovieDesk, isImageDesk, isGif, setComplete } = useLottiePlayer();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isMovieDesk || isImageDesk || isGif) {
      setOpen(openLottie);
    } else {
      setOpen(true);
    }
  }, [openLottie, isMovieDesk, isImageDesk]);

  return (
    <>
      {open
        &&
        <Lottie
          autoplay={true}
          loop={loop === true}
          interactivity={scroll && interactivity}
          animationData={animation}
          style={{ height: height ? height : '100%', width: width ? width : '100%' }}
          onComplete={() => setComplete(true)}
        />
      }
    </>
  );
}
