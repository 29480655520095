import { Container } from "@material-ui/core";
import styled from "styled-components";

export const ContentRelative = styled.main(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    paddingBottom: 60
}));

export const ContentDescLottie = styled(Container)(({ theme }) => ({
    paddingTop: 40,
    paddingBottom: 30
}));

export const styleGrid = {
    justifyContent: "center",
    color: "#fff",
    margin: 0,
    width: "100%"
};