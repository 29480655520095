import { CardActionArea } from "@material-ui/core";
import styled from "styled-components";

export const CardActionAreaComp = styled(CardActionArea)(({ theme }) => ({
  height: 800,
  width: '44vw',
  borderRadius: 9,
  color: theme.palette.primary.main
}));

export const ContentDescLeftFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContentDescLeft = styled.div`  
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 2vw;
  padding-bottom: 103px;
  margin-top: 80px;
`;

export const ContentImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top:-270px;
`;

export const ContentTitle = styled.div`
  padding-bottom: 5px;
`;