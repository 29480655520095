import React from "react"
import IFrameIntro from "../IFrameIntro"
import { useLottiePlayer } from "../../../Contexts/LottiePlayer"
import MyImage from "../MyImage";
import { IMyMediaIntro } from "./interface";
import { Box, useTheme } from "@material-ui/core";

const C_MOVIE_URL = 'https://youtu.be/625RxLZM0hY?si=CdFZTfTE30_N10bC';
const gifIntro = "https://d13x8u4oijb9vw.cloudfront.net/movie-intro-bdr-mobile.gif";

export default function MyMediaIntro({ img, title }: IMyMediaIntro) {
    const theme = useTheme();
    const { isMovieDesk, isGif, isImageDesk } = useLottiePlayer();

    return (
        <Box style={{ backgroundColor: theme.palette.background.paper }} >
            {isMovieDesk ?
                <IFrameIntro height="100vh" isMovieIntro movie={C_MOVIE_URL} />
                :
                (isGif || isImageDesk) ? <MyImage title={title} img={isGif ? gifIntro : img} /> : false}
        </Box>
    )
}