import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ContentPrinc, GridPrinc } from "./styles";
import PatrocItem from "./PatrocItem";
import objData from "./data.js";
import MyFlexCenter from "../MyFlexCenter";

export default function Patroc() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ContentPrinc>
      <MyFlexCenter>
        <GridPrinc
          container
          spacing={isSmDown ? 0 : 2}
        >
          {objData.map((e, count) => (
            <PatrocItem key={count} {...e} count={count} />
          ))}
        </GridPrinc>
      </MyFlexCenter>
    </ContentPrinc>
  );
}
