import { Box, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { ContentScale, IFrameStyle } from "./styles";
import { useLottiePlayer } from "../../../Contexts/LottiePlayer";
import { useScroll } from "react-use-gesture";
import { brightnessImageIntro } from "../../../Comum";
import ReactPlayer from "react-player";
import { Waypoint } from 'react-waypoint';
import { IIFrameItem } from "./interface";
import { transition } from "./data";
import { useMain } from "../../../Contexts/main";

export default function IFrameIntro({ height, isMovieIntro, movie, children }: IIFrameItem) {
    const animContent = useAnimation();
    const [playing, setPlay] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [url, setUrl] = useState(movie);
    const { setLoad, onLoad } = useMain();
    const { setOpenLottie, setIsMovieDesk, setIsGif } = useLottiePlayer();
    const theme = useTheme();

    useEffect(() => {
        if (loaded) { // so quando remove a url no evento de scroll
            setLoaded(url !== '');
        } else {
            setLoad(true);
        }
    }, [url]);

    useEffect(() => {
        animOp(loaded ? 1 : 0);
    }, [loaded]);

    const handleSetLoad = (ALoading: boolean) => {
        setLoad(ALoading);
    }
    const animOp = async (AOpacity: number) => {
        await animContent.start({
            opacity: AOpacity
        });
        setOpenLottie(AOpacity === 1);
    }

    const handleError = async () => {
        animOp(0);
        setIsMovieDesk(false);
        setIsGif(true);
    }

    const hanldeProgress = () => {
        if (onLoad) {
            setLoad(false);
        }
        setLoaded(true);
    }

    return (
        <Waypoint
            onEnter={() => setPlay(true)}
            onLeave={() => setPlay(false)}
        >
            <Box>
                <Box
                    style={{
                        position: isMovieIntro ? 'absolute' : 'relative',
                        height: height,
                        width: "100%",
                        backgroundImage: loaded && 'linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 20%), rgb(0 0 0 / 38%), rgb(0 0 0 / 47%), rgba(0, 0, 0, 0.6))',
                        zIndex: 2
                    }}
                />
                <motion.div
                    style={{ position: "relative", zIndex: 1 }}
                    initial={{ opacity: 0 }}
                    animate={animContent}
                    transition={transition}
                >
                    <ContentScale theme={theme}>
                        <ReactPlayer
                            config={{
                                youtube: {
                                    // playerVars: [],
                                    // embedOptions: {"rel=0"},
                                    onUnstarted: () => console.log('unstarted'),
                                },

                            }}
                            theme={theme}
                            playing={playing}
                            loop
                            controls={false}
                            muted
                            height={"100%"}
                            width={"100%"}
                            // onBuffer={() => setLoaded(true)}
                            // onBufferEnd={() => setLoaded(true)}
                            onProgress={(e) => hanldeProgress()}
                            // onProgress={(e) => ((e.playedSeconds > 0) && (e.playedSeconds < 1)) ? setLoaded(true) /*animOp(1)*/ : false}
                            // onStart={() => animOp(1)}
                            // onReady={() => animOp(1)} //setOpenLottie(true)}
                            // onPlay={() => animOp(1)}                    
                            onError={handleError}

                            url={url}
                            // url="http://www.youtube.com/embed/lewtLhEJETQ"
                            children={children}
                        />
                    </ContentScale>
                </motion.div>
            </Box>
        </Waypoint>

    )

}