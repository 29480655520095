
import { motion } from "framer-motion"
import styled from "styled-components";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIconMaterial from '@material-ui/icons/Pause';
import { IPlayers, IContentMedia, IPauseIcon, IContentTypography } from "./interface";

import { C_WIDTH_CARD_MATERIA, C_HEIGHT_CARD_MATERIA } from "./MediaCard/data";

export const ContentCardMateria = styled(motion.div)<IContentMedia>(({ theme, img, play }) => ({
    zIndex: 1,
    userSelect: "none",
    cursor: "pointer",
    minHeight: "250px",
    "&:hover": {
        [ContentShadow]: {
            opacity: 0,
        },
        [ContentShadowHov]: {
            opacity: 1,
        },
        [PlayIcon]: {
            opacity: play ? 0 : 1
        },
        [PauseIcon]: {
            opacity: play ? 1 : 0
        },
        [ContentTypography]: {
            opacity: 0.9
        }
    },
    [theme.breakpoints.down('sm')]: {
        [PlayIcon]: {
            opacity: play ? 0 : 1
        },
        [PauseIcon]: {
            opacity: play ? 1 : 0
        },
        [ContentTypography]: {
            opacity: 1
        },
        [ContentShadow]: {
            opacity: 0,
        },
        [ContentShadowHov]: {
            opacity: 1,
        },
    }
}));



export const PauseIcon = styled(PauseIconMaterial)<IPauseIcon>(({ theme, opacityicon }) => ({
    opacity: opacityicon,
    padding: 9,
    zIndex: 3,
    transition: "ease .3s"
}));

export const PlayIcon = styled(PlayArrowIcon)(({ theme }) => ({
    opacity: 0,
    padding: 9,
    zIndex: 3,
    transition: "ease .3s"
}));


export const ContentPlayIcon = styled.div(({ theme }) => ({
    top: 0,
    position: "absolute",
    height: C_HEIGHT_CARD_MATERIA,
    width: C_WIDTH_CARD_MATERIA,
    display: 'flex',
    alignItems: "flex-end",
    paddingBottom: 9,
    zIndex: 3,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
}));


export const ContentTypography = styled.div<IContentTypography>(({ theme, istitle }) => ({
    height: "100%",
    width: C_WIDTH_CARD_MATERIA,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    opacity: istitle ? 0.8 : 1,
    paddingTop: 10,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
}));

export const ContentMedia = styled(motion.div)<IContentMedia>(({ theme, img }) => ({
    height: C_HEIGHT_CARD_MATERIA,
    width: C_WIDTH_CARD_MATERIA,
    borderRadius: 9,
    userSelect: "none",
    zIndex: 1,
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
}));

export const ContentMediaImg = styled(motion.img)(({ }) => ({
    height: '100%',
    width: '100%',
    blockSize: "auto",
    aspectRatio: "1/1",
    objectFit: "cover",
    objectPosition: "top center",
    borderRadius: 9,
    userSelect: "none",
    zIndex: 1,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
}));

export const ContentMediaPrinc = styled(motion.div)(({ theme }) => ({
    height: C_HEIGHT_CARD_MATERIA,
    width: C_WIDTH_CARD_MATERIA,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 9,
    userSelect: "none",
}));


export const ContentShadow = styled.div(({ theme }) => ({
    height: C_HEIGHT_CARD_MATERIA,
    width: C_WIDTH_CARD_MATERIA,
    position: 'absolute',
    top: 0,
    zIndex: 2,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 10%), rgb(0 0 0 / 20%), rgb(0 0 0 / 30%), rgb(0 0 0 / 45%), rgba(0, 0, 0, 0.5))`,
    opacity: 1,
    borderRadius: 9,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)",
}));

export const ContentShadowHov = styled.div(({ theme }) => ({
    height: C_HEIGHT_CARD_MATERIA,
    width: C_WIDTH_CARD_MATERIA,
    position: 'absolute',
    top: 0,
    zIndex: 2,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 10%), rgb(0 0 0 / 15%), rgb(0 0 0 / 20%), rgb(0 0 0 / 25%), rgba(0, 0, 0, 0.3))`,
    opacity: 0,
    borderRadius: 9,
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)",
}));

