import { Box } from "@material-ui/core";
import React from "react";

interface IMyFadeUp {
    children: React.ReactNode;
    fade?: boolean | undefined;
}

export default function MyFadeUp({ children, fade }: IMyFadeUp) {
    return (
        fade
            ?
            <Box
                // style={{ width: '100%', height: '100%' }}
                data-aos="fade"
                data-aos-delay="0"
                children={children}
            />
            :
            <Box children={children} />

    )
}