import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Box,
  IconButton,
  Container,
  Divider,
  Typography,
  Link,
  useMediaQuery
} from "@material-ui/core";
import { loadImage } from "../../Comum";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MyLogo from "../MyLogo";
import { useMain } from "../../Contexts/main";
import MyTypographyDesc from "../MyTypography/MyTypographyDesc";
import { ImagemMaterias } from "../GridMater/data";

import { useStyles } from "./styles";
import { transition } from "../CardHover/CardUp/data";
import { ContentMotion } from "./styles.ts";
import MyImageLazzy from "../MyImageLazzy";
import MyLink from "../MyLink";

export const logoBDRHoriz = "https://d13x8u4oijb9vw.cloudfront.net/Bdr-logo-horiz.png";

const Foot = function FootComp(props) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(props);
  const history = useHistory();
  const { setContact } = useMain();

  const [img, setImg] = useState("");

  function setarRouter(APath) {
    setTimeout(() => {
      if (APath === location.pathname) {
        scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        history.push(APath);
      }
    }, 300);
  }

  function setarLinkExternal(ALink) {
    setTimeout(() => {
      history.push(ALink);
    }, 300);
  }


  const hanldeEnter = (Aimg) => {
    if (img === '') {
      setImg(Aimg)
    }
  };

  const hanldeExit = () => setImg("");

  const myBtnText = (ADesc) => (
    <MyTypographyDesc
      fade={false}
      fontFamily="medium-l"
      fontxs={'10px'}
      fontsm={'10px'}
      fontmd={'10px'}
      fontlg={'10px'}
      fontxl={'10px'}
      children={ADesc}
    />
  )


  return (
    <footer>
      <MyImageLazzy
        title=""
        backgrondLinear="rgba(0, 0, 0, 0), rgb(0 0 0 / 25%), rgb(0 0 0 / 50%), rgb(0 0 0 / 55%), rgba(0, 0, 0, 0.6))"
        borderRadius={'0px'}
        image={ImagemMaterias}
        // image={"https://img.freepik.com/free-photo/aerial-view-summer-harvest-combine-harvester-harvesting-large-field_661209-4.jpg?w=1380&t=st=1692381397~exp=1692381997~hmac=78d35a31088ced93d3bad5fddfda57b05431ff9b803cefe4a6f96e13a7ea5ad9"}
        backgroundPosition={"center"}
        height='100%'
        width='100%'
      >
        <Container maxWidth="xl" >
          <Box pt={8} pb={1} >
            {isSm ?
              <Box className={classes.contentSm}>
                <Box className={classes.contentSecSm}>
                  <Box className={classes.contentTSm}>
                    <Box pb={1} className={classes.contentLogo} >
                      <Box width={'100%'} >
                        <MyLogo logo={logoBDRHoriz} setarRouter={(APath) => setarRouter(APath)} />
                      </Box>
                    </Box>
                    <Box pb={1} >
                      <MyLink href={"/materias"}>
                        <Button className={classes.itemSobre} color="primary" onClick={() => setarRouter("/materias")} size="medium">
                          {myBtnText('Matérias')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pb={1} >
                      <MyLink href={"/patrocinadores"}>
                        <Button
                          className={classes.itemSobre}
                          color="primary"
                          onClick={() => setarRouter("/patrocinadores")}
                          size="medium"
                        >
                          {myBtnText('Patrocinadores')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pb={1}>
                      <MyLink href={"/equipe"}>
                        <Button color="primary" className={classes.itemSobre} onClick={() => setarRouter("/equipe")} size="medium">
                          {myBtnText('Equipe')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pb={1}>
                      <MyLink href={"/sobre"}>
                        <Button color="primary" className={classes.itemSobre} onClick={() => setarRouter("/sobre")} size="medium">
                          {myBtnText('Sobre')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pb={1}>
                      <Button color="primary" className={classes.itemSobre} onClick={() => setContact(true)} size="medium">
                        {myBtnText('Contato')}
                      </Button>
                    </Box>
                  </Box>
                  <Box className={classes.contentSocialSm} >
                    <Box textAlign={"center"} mt={{ xs: .4, md: 0 }} my={2}>
                      <Link href="https://www.facebook.com/bomdiarural" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<FacebookIcon style={{ color: theme.palette.primary.main }} />} />} />
                      <Link href="https://www.instagram.com/bomdiaruraltv" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<InstagramIcon style={{ color: theme.palette.primary.main }} />} />} />
                      <Link href="https://www.youtube.com/channel/UCbFX6KlIvmunDv0uXrp2wDw" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<YouTubeIcon style={{ color: theme.palette.primary.main }} />} />} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
              <Box className={classes.contentSmUp}>
                <Box className={classes.contentSecSmUp}>
                  <Box pb={1} >
                    <MyLogo logo={logoBDRHoriz} setarRouter={(APath) => setarRouter(APath)} />
                  </Box>
                  <Box className={classes.contentBtn} >
                    <Box>
                      <MyLink href={"/materias"}>
                        <Button className={classes.itemSobre} color="primary" onClick={() => setarRouter("/materias")} size="medium">
                          {myBtnText('Matérias')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pl={1} >
                      <MyLink href={"/patrocinadores"}>
                        <Button
                          className={classes.itemSobre}
                          color="primary"
                          onClick={() => setarRouter("/patrocinadores")}
                          size="medium"
                        >
                          {myBtnText('Patrocinadores')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pl={1}>
                      <MyLink href={"/equipe"}>
                        <Button color="primary" className={classes.itemSobre} onClick={() => setarRouter("/equipe")} size="medium">
                          {myBtnText('Equipe')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pl={1}>
                      <MyLink href={"/sobre"}>
                        <Button color="primary" className={classes.itemSobre} onClick={() => setarRouter("/sobre")} size="medium">
                          {myBtnText('Sobre')}
                        </Button>
                      </MyLink>
                    </Box>
                    <Box pl={1}>
                      <Button color="primary" className={classes.itemSobre} onClick={() => setContact(true)} size="medium">
                        {myBtnText('Contato')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.contentSocial} >
                  <Box display={'flex'} justifyContent={'flex-end'} mt={{ xs: .4, md: 0 }} my={2}>
                    <Link href="https://www.facebook.com/bomdiarural" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<FacebookIcon style={{ color: theme.palette.primary.main }} />} />} />
                    <Link href="https://www.instagram.com/bomdiaruraltv" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<InstagramIcon style={{ color: theme.palette.primary.main }} />} />} />
                    <Link href="https://www.youtube.com/channel/UCbFX6KlIvmunDv0uXrp2wDw" target="_blank" children={<IconButton color="primary" className={classes.btnSocial} size="medium" children={<YouTubeIcon style={{ color: theme.palette.primary.main }} />} />} />
                  </Box>
                </Box>
              </Box>
            }
          </Box>

          <Box pt={2} pb={10} >
            <Box className={classes.boxNavTermos}>
              <Box className={classes.boxNavTermosSec} color={"#fff"} >
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  color="primary"
                  size="medium"
                >
                  <MyTypographyDesc
                    fontFamily="light"
                    style={{ color: theme.palette.primary.main, textTransform: "none" }}
                    children="@Company UX 2019-2024. Todos os direitos reservados."
                    fontxs={'10px'}
                    fontsm={'10px'}
                    fontmd={'10px'}
                    fontlg={'12px'}
                    fontxl={'12px'}
                    variant="caption"
                  />
                </Button>
              </Box>
            </Box>
          </Box >
        </Container>
      </MyImageLazzy>
    </footer>
  );
};

export default Foot;