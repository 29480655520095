import { Box, Container } from "@material-ui/core";
import styled from "styled-components";

export const CardActionAreaComp = styled(Box)(({ }) => ({
    height: "100%",
    width: '100%',
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    borderRadius: 4,
    color: '#fff'
}));

export const ContainerPrinc = styled(Container)`
    max-width: 1100px
`;

export const ContentImg = styled.div`
    max-width: 650px;
    height: 100%;
    width: 100%;
`;