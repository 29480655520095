import { Container } from "@material-ui/core";
import styled from "styled-components";

export const Content = styled.div`
  padding-top: 103px;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65vh;
  text-align: center;
  align-items: center;
  width: 100%;  
  background-color: transparent;
`;

export const ContentDesc = styled.div(({ theme }) => ({    
    paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
        maxWidth: 450,
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: 640
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: 640
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 680
    },
}));

export const ContainerTextLast = styled.div(({ theme }) => ({    
    [theme.breakpoints.down('xs')]: {        
    }
}));