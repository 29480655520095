import { ImgBgColab } from "../GridColab";
import { ImgEquipeBdr } from "../GridEquipe";

export default [
    {
        pathName: "/equipe",
        desc: "Nossa equipe",
        buttonDesc: "Conheça",
        img: ImgEquipeBdr,
        title: "Equipe do Bom Dia Rural"

    },
    {
        pathName: "/patrocinadores",
        desc: "Nossos patrocinadores",
        buttonDesc: "Conheça",
        img: ImgBgColab,
        title: "Plantação Rural"
    }
];
