import React, { memo } from 'react';
import { Box, Dialog, Container, useTheme } from "@material-ui/core";
import { IMyOverlayMovie } from './interface';
import MyIFrame from '../../MyIFrame';
import MyFrameColab from '../../GridColab/ColabItem/MyFrameColab';
import { TypographyDesc } from '../../MyTypography/MyTypographyDesc/styles';

function MyOverlayMovie({ open, setClose, videoId, desc }: IMyOverlayMovie) {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            style={{ margin: 0 }}
            onClose={() => setClose()}
            PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
            PaperComponent={() => (
                open &&
                <Box style={{ maxWidth: 1280, width: "100%" }} >
                    <MyFrameColab
                        animOpIntro
                        muted={false}
                        url={"https://www.youtube.com/embed/" + videoId}
                    />
                </Box>
            )}
        />
    )
}

export default memo(MyOverlayMovie);