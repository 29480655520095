import { Container, Grid } from "@material-ui/core";
import styled from "styled-components";

export const ContentPrinc = styled.main(({ theme }) => ({
  minHeight: '100vh',
  width: "100%",
  background: theme.palette.background.default,
  paddingBottom: 60
}));

export const ContentBgColor = styled.div`  
  width: 100%;
  background-color: inherit;
  min-height: 1000px;
`;

export const ContainerData = styled(Container)`
  min-height: 100vh;
`;

export const ContainerLottieDesc = styled(Container)`
  padding: 40px 0px 35px 0px;
`;

export const GridItemColab = styled(Grid)`
  display: flex;
  justify-content: center;
`;