import { motion } from "framer-motion";
import { CardActionArea } from "@material-ui/core";
import styled from "styled-components";
import { ICardActionAreaComp, IDescContent } from "./interface";

export const CardActionAreaComp = styled(CardActionArea)<ICardActionAreaComp>(({ isleft, ismid, theme }) => ({
    height: '100%',
    width: '100%',
    color: theme.palette.primary.main,
    borderTopLeftRadius: isleft ? 9 : ismid ? 0 : 0,
    borderTopRightRadius: isleft ? 0 : ismid ? 0 : 9,
    borderBottomLeftRadius: isleft ? 9 : ismid ? 0 : 0,
    borderBottomRightRadius: isleft ? 0 : ismid ? 0 : 9,
    [theme.breakpoints.down('sm')]: {
        borderRadius: 9,
    }
}));

export const DescContent = styled(motion.div)<IDescContent>(({ theme, ismid }) => ({
    paddingBottom: 16,
    textAlign: "center",
    userSelect: "none",
    [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
        borderRadius: 9
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: ismid ? 375 : 350
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: ismid ? 360 : 350
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: ismid ? 380 : 370
    }
}));

export const DescTitle = styled(motion.div)<IDescContent>(({ theme }) => ({
    paddingBottom: 16,
    textAlign: "center",
    userSelect: "none",
    // maxWidth: 350,   
    [theme.breakpoints.down('sm')]: {
        borderRadius: 9
    },
    [theme.breakpoints.up('md')]: {
        // maxWidth: 350,

    }
}));



