import { InteractivityProps } from "lottie-react";

export const interactivity: Omit<InteractivityProps, "lottieObj"> = {
    mode: "scroll",
    actions: [
        {
            visibility: [0, 0.68],
            type: "seek",
            frames: [0, 125]
        },
        {
            visibility: [0.68, 1],
            type: "stop",
            frames: [125]
        },
    ]
};