import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
    useTheme
} from "@material-ui/core";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import './hidescrollbar.css';
import "react-horizontal-scrolling-menu/dist/styles.css";

import { Waypoint } from 'react-waypoint';
import useDrag from "../../../MyHorizontalScrolling/useDrag";
import MyCard from "../../../MyHorizontalScrolling/MyCard";
import LeftArrow from "../../../MyHorizontalScrolling/MyArrows/LeftArrow";
import RightArrow from "../../../MyHorizontalScrolling/MyArrows/RightArrow";
import { ContentPadding, ContentMinHeight } from "./styles";
import { ICardHoverUp, IDataMater, IGetYoutubeMoviesRes } from "./interface";
import { returnImgResolution } from "./utils";
import { scrollVisibilityApiType } from "./types";
import { dataEmpty } from "./dataMater";

export default function Materias({ link, title }: ICardHoverUp) {
    const theme = useTheme();
    const [dataMater, setDataMater] = useState<IDataMater[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const mountedRef = useRef(true);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
            setDataMater([]);
        }
    }, []);

    const handleLoad = () => {
        if (dataMater.length !== 0) return false;
        setIsLoading(true);
        axios.get<IGetYoutubeMoviesRes>(link)
            .then((res) => {
                const newData: IDataMater[] = [];
                res.data.items.map((data => {
                    newData.push({
                        description: data.snippet.description,
                        img: returnImgResolution(data.snippet.thumbnails),
                        title: data.snippet.title,
                        videoId: data.snippet.resourceId.videoId
                    });
                }));
                if (!mountedRef.current) return null
                setDataMater(newData);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                // setIsLoading(false);
            });
    }

    // NOTE: for drag by mouse
    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (ev: React.MouseEvent) => {
        if (!isLoading) {
            dragMove(ev, (posDiff) => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });
        }
    }

    // const handleMouseDown = () => !isLoading && dragStart;
    // const hanldeMouseUp = () => !isLoading && dragStop;
    // const handleMouseMove = () => !isLoading && handleDrag;

    return (
        <Waypoint
            onEnter={() => handleLoad()}
            onLeave={() => { }}
        >
            <ContentMinHeight onMouseLeave={dragStop}>
                <ScrollMenu
                    LeftArrow={!isLoading && LeftArrow}
                    RightArrow={!isLoading && RightArrow}
                    // onWheel={onWheel}
                    onMouseDown={() => dragStart}
                    onMouseUp={() => dragStop}
                    onMouseMove={handleDrag}
                >
                    {isLoading
                        ?
                        dataEmpty.map((data, key) => (
                            <ContentPadding
                                theme={theme}
                                pos={key}
                                lenght={dataEmpty.length - 1}
                                key={key}
                            >
                                <MyCard img={""} title={""} desc={""} videoId={""} id={key.toString()} />
                            </ContentPadding>
                        ))
                        :
                        dataMater.map((e, key) => (
                            <ContentPadding
                                theme={theme}
                                pos={key}
                                lenght={dataMater.length - 1}
                                key={key}
                            >
                                <MyCard img={e.img} title={e.title} desc={e.description} videoId={e.videoId} id={key.toString()} />
                            </ContentPadding>
                        ))
                    }
                </ScrollMenu >
            </ContentMinHeight>
        </Waypoint>
    );
}
