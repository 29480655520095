import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { spacingElements } from "../../Comum";

export const GridContainer = styled(Grid)`
    width: 100%;
    margin: 0px;
`;

export const Griditem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        paddingBottom: spacingElements,
    }
}));