import { Container, Grid, Theme } from "@material-ui/core";
import styled from "styled-components";

export const ContentbgImageItem = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IGridComp {
    theme: Theme;
}

export const GridComp = styled(Grid) <IGridComp>`
    height: 650px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    ${props => props.theme.breakpoints.down("md")}{
        height: 100%;
    }
`;

export const GridItem = styled(Grid)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const ContainerComp = styled.div(({ theme }) => ({
    // paddingTop: 16,
    // paddingBottom: 16,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
    }
}));