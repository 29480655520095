import React, { useEffect, useState } from "react";
import { Box, Container, Dialog } from "@material-ui/core";
import MyLottie from "../MyLottie";
import contactAnim from "../../Assets/animation/contactAnim.json"
import { useMain } from "../../Contexts/main";

export default function Contact() {
    const { openContact, setContact } = useMain();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if ((open && !openContact) || (!open && openContact)) {
            setOpen(openContact);
        }
    }, [openContact]);

    return (
        <Dialog
            open={open}
            style={{ margin: 0 }}
            onClose={() => setContact(false)}
            PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
            PaperComponent={() => (
                open &&
                <Container maxWidth={"sm"} >
                    <Box width="100%" >
                        <MyLottie loop={false} animation={contactAnim} />
                    </Box>
                </Container>
            )}
        />

    )
}