import styled from "styled-components";

export const C_PADDING_LEFT_MATER_DESC_SM = 16;
export const C_PADDING_LEFT_MATER_DESC = 60;

export const ContentPadding = styled.div(({ theme }) => ({
    paddingBottom: 16,
    paddingLeft: C_PADDING_LEFT_MATER_DESC,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: C_PADDING_LEFT_MATER_DESC_SM,
    }
}))