import React, { useState } from "react";
import { Box } from "@material-ui/core";
import MyOverlayMovie from "../MyOverLayMovie";
import { IMyCard } from "./interface";
import MediaCard from "./MediaCard";

export default function MyCard({ img, title, videoId, desc, id }: IMyCard) {
  const [openMovie, setOpenMovie] = useState<boolean>(false);

  return (
    <Box style={{ overflow: "hidden", userSelect: "none" }}>
      <MyOverlayMovie open={openMovie} videoId={videoId} desc={desc} setClose={() => setOpenMovie(false)} />
      <MediaCard id={id} desc={desc} img={img} title={title} videoId={videoId} openMovie={openMovie} setOpenMovie={(AOpen) => setOpenMovie(AOpen)} />
    </Box>
  );
}
