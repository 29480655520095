import { Box, Container } from "@material-ui/core"
import styled from "styled-components";
import { IContentPuller, ITheme } from "./interface";

export const Root = styled('div')(({ theme }) => ({
  height: '100%'
}));

export const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 4,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 3,
  position: 'absolute',
  top: 10,
  left: 'calc(50% - 15px)',
}));

export const ContentPuller = styled.div<IContentPuller>`
    /* background-color: ${props => props.theme.palette.secondary.light}; */
    /* background-color: transparent; */
    height: 25px;    
    display: ${props => props.openModal ? "block" : "none"};
    /* position: absolute; */
    /* top: ${props => props.openModal ? "-20px" : "0px"}; */
    /* border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
    /* visibility: visible;
    right: 0px;
    left: 0px; */
`;

export const ContentButton = styled.div<ITheme>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    /* background-color: ${props => props.theme.palette.primary.main}; */

`;

export const ContainerComp = styled(Container) <ITheme>`
    height: 100%;
    // background-color: ${props => props.theme.palette.background.default};
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;   
    padding-bottom: 10px;
    /* background-color: transparent; */
`;