import React, { useState } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import CardItem from "./CardItem";
import data from "./data.js";
import IProps from "./interface";
import MyImageLazzy from "../MyImageLazzy";
import { ContentbgImageItem, GridComp, GridItem, ContainerComp } from "./styles";
import MyFadeUp from "../MyFadeUp";

const cardImg = "https://d13x8u4oijb9vw.cloudfront.net/info-img.jpg";

export default function CardInformation(props: IProps) {
    const theme = useTheme();
    const [backgroundPosition, setBackgroundPosition] = useState("center");

    const handleHover = (APos: number) => setBackgroundPosition(APos === 1 ? "top" : APos === 2 ? "center" : "bottom");

    return (
        <MyFadeUp fade >
            <MyImageLazzy
                title=""
                backgrondLinear="rgba(0, 0, 0, 0), rgb(0 0 0 / 25%), rgb(0 0 0 / 50%), rgb(0 0 0 / 55%), rgba(0, 0, 0, 0.6))"
                borderRadius={'9px'}
                image={cardImg}                
                backgroundPosition={backgroundPosition}
                height='100%'
                width='100%'
            >
                <ContainerComp theme={theme}>
                    <GridComp theme={theme} container spacing={0}>
                        {data.map((e, key) => {
                            return (
                                <GridItem item key={key} xs={12} sm={12} md={12} lg={4} xl={4} >
                                    <ContentbgImageItem>
                                        <CardItem desc={e.desc} titleFirst={e.titleFirst} titleSec={e.titleSec} position={e.position} handleHover={(APos) => handleHover(APos)} />
                                    </ContentbgImageItem>
                                </GridItem>
                            );
                        })}
                    </GridComp>
                </ContainerComp>
            </MyImageLazzy>
        </MyFadeUp>
    );
}
