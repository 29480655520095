import React, { useEffect } from "react";
import { IMyLottieScroll } from "./interface";
import { motion } from "framer-motion";
import MyLottie from "../../MyLottie";

import scrollAnim from "../../../Assets/animation/scrollDown.json";
import { useLocation } from "react-router-dom";
import { useAnimation } from "framer-motion";
import { Box, IconButton } from "@material-ui/core";

const transition = {
    // ease: [0, 0, 0, 0],
    duration: 0.3,
    // type: "tween",
    // duration: 0.6
    ease: [0.40, 0.40, 0.60, 0.70]
};


export default function MyLottieScroll({ }: IMyLottieScroll) {
    const { pathname } = useLocation();
    const animateOpacity = useAnimation();

    useEffect(() => {
        animOp();
    }, [pathname]);

    const animOp = async () => {
        await animateOpacity.start({
            opacity: 0
        });
        await animateOpacity.start({
            opacity: 0.7
        });
    }

    const handleClickIconBtn = () => {
        setTimeout(() => {
            window.scroll({ behavior: 'smooth', top: 750 })
        }, 150);
    };

    return (
        <motion.div
            style={{
                // display: "flex",
                // justifyContent: "center",
                // width: '100%',                
                position: "absolute",
                // left: '50%',
                bottom: 0,
            }}
            initial={{ opacity: 0 }}
            animate={animateOpacity}
            transition={transition}
        >
            <IconButton size="small" color="primary" onClick={() => handleClickIconBtn()} >
                <Box children={<MyLottie height={50} width={50} animation={scrollAnim} loop />} />
            </IconButton>
        </motion.div>
    )
}