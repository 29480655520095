import styled from "styled-components";
import { C_PADDING_LEFT_MATER_DESC_SM, C_PADDING_LEFT_MATER_DESC } from "../styles";
import { IContentPadding } from "./interface";

export const ContentPadding = styled.div<IContentPadding>(({ theme, pos, lenght }) => ({
    paddingLeft: pos === 0 ? C_PADDING_LEFT_MATER_DESC : 0,
    paddingRight: pos === lenght ? 15 : 0,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: pos === 0 ? C_PADDING_LEFT_MATER_DESC_SM : 0,
        paddingRight: pos === lenght ? 7 : 0,
    }
}));

export const ContentMinHeight = styled.div`
    min-height: 300px;
`;
