import { Box, useTheme } from "@material-ui/core";
import { ContentPrinc, TypographySticky } from "./styles";
import { ContentDescAlign } from "../DescComp/styles";

export default function TextCenter(props) {
  const theme = useTheme();
  return (
    <ContentPrinc padding={props.padding}>
      <Box style={{ maxWidth: 650 }} >
        <ContentDescAlign theme={theme} >
          <div style={{ paddingBottom: 5 }} >
            <TypographySticky
              fade
              fontFamily="semi-bold"
              component="h2"
              style={{ color: "#ffdb21" }}
              theme={theme}
              fontxs={'1.60rem'}
              fontsm={'1.90rem'}
              fontmd={'1.90rem'}
              fontlg={'1.90rem'}
              fontxl={'1.90rem'}
              children={props.title}
            />
          </div>
          <TypographySticky
            fade
            fontFamily="light"
            color="primary"
            component="p"
            theme={theme}
            fontxs={theme.typography.body1.fontSize.toString()}
            fontsm={theme.typography.h6.fontSize.toString()}
            fontmd={theme.typography.h6.fontSize.toString()}
            fontlg={theme.typography.h6.fontSize.toString()}
            fontxl={theme.typography.h6.fontSize.toString()}
            children={props.desc}
          />
        </ContentDescAlign>
      </Box>
    </ContentPrinc>
  );
}
