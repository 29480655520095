import { motion } from "framer-motion";
import styled from "styled-components";
import Aspas from '@material-ui/icons/FormatQuote';
import { IPapperComp } from "./interface";

const transition = "ease 0.3s";

export const ContentPrinc = styled.div`  
  height: 100%;
  min-height: 200px;
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
  color: #ffff;
  justify-content: space-between;
  align-items: center;
  transition: ${transition};
  @media (max-width: 600px) {
    height: 100%;
    padding-top: 0px;
  }
`;

export const ImgAspas = styled.img`
  height: 30px;
  width: 30px;
  transition: ${transition};
`;

export const ContentDesc = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ContentName = styled.div`  
  line-height: 1.75;
  letter-spacing: 1.1px;
  font-weight: bold;
`;

export const ContentSocial = styled.div`
  /* font-size: 0.75rem; */
  /* text-transform: uppercase; */
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 1px;  
`;

export const ContentLogo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  bottom: 0;
  /* transform: translateY(60px); */
`;

export const ContentSecLogo = styled.div`
  background-color: #ffff;
  padding-top: 20px;
  transform: "scale(10)";
  padding-bottom: 20px;
  /* padding-left: 25px;
  padding-right: 25px; */
  border-radius: 50%;
  transition: ${transition};
`;

export const ContentGrid = styled(motion.div)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 40px; */
`;

export const PaperComp = styled.div<IPapperComp>`
  border: none;
  box-shadow: 0;
  height: 100%;  
  border-radius: 9px;
  background-color: ${props => props.bgColor};  
  width: 100%;  
  box-shadow: none;
`;

export const AspasComp = styled(Aspas)(({ theme }) => ({
  height: 26,
  width: 26,
  color: 'inherit',
  opacity: .8,
  [theme.breakpoints.down('xs')]: {
    height: 20,
    width: 20,
  }
}));


export const ContentMaxWidth = styled.div(({ theme }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
  paddingBottom: 0,
  [theme.breakpoints.down('xs')]: {
    maxWidth: 320
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 390
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 350
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 350
  }
}));