import React from "react";
import { useTheme } from "@material-ui/core";
import { IMyFlexCenter } from "./interface";
import { ContentFlexCenter, ContentMw } from "./styles";

export default function MyFlexCenter({ children }: IMyFlexCenter) {
    const theme = useTheme();
    return (
        <ContentFlexCenter theme={theme} >
            <ContentMw theme={theme} >
                {children}
            </ContentMw>
        </ContentFlexCenter>
    )
}