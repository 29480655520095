import { IDataHelmet } from "./interface";

export const dataHelmet: IDataHelmet =
{
    index: {
        title: "Bom Dia Rural - O Programa de quem planta e produz.",
        desc: "O Programa é exibido na TV Sudoeste, afiliada à RedeTV, todos os domingos de manhã, às 10 horas, com reprise nas segundas-feiras à tarde, às 13:30 horas."        
    },
    materias: {
        title: "Matérias",
        desc: "Reportagens jornalísticas que contam histórias, relatam experiências da vida rural, tendências do mercado, além das inovações tecnológicas aplicadas ao campo."        
    },
    patrocinadores: {
        title: "Patrocinadores",
        desc: "Profissionais dedicados e comprometidos com o crescimento. Especialistas em suas áreas, fundamentais para o Bom Dia Rural."        
    },
    equipe: {
        title: "Equipe",
        desc: "Contamos com nosso time de profissionais altamente qualificados e com experiência no jornalismo há mais de 10 anos."
    },
    sobre: {
        title: "Sobre",
        desc: "Saiba mais sobre o programa que tem mais de 600 mil telespectadores e mais de 15 mil seguidores nas redes sociais."        
    }
}
