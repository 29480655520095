import React from "react";
import { Box, useTheme } from "@material-ui/core";
import MyFadeUp from "../MyFadeUp";
import { ContentMap, ContentMwMap, IFrameGoogleMaps } from "./styles";
import { motion, useAnimation } from "framer-motion";
import { transition } from "../CardHover/CardUp/data";
import { linkGoogleMaps } from "./data";

export default function MyMap() {
    const theme = useTheme();
    const animO = useAnimation();

    return (
        <MyFadeUp fade>
            <ContentMap theme={theme}>
                <ContentMwMap theme={theme} >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={animO}
                        transition={transition}
                    >
                        <IFrameGoogleMaps
                            src={linkGoogleMaps}
                            onLoad={() => animO.start({ opacity: 1 })}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </motion.div>
                </ContentMwMap>
            </ContentMap>
        </MyFadeUp>
    );
}
