import { IPages } from './interface';

export const pages: IPages[] = [
    {
        page: 'Início',
        router: '/',
    },
    {
        page: 'Matérias',
        router: '/materias',
    },
    {
        page: 'Patrocinadores',
        router: '/patrocinadores',
    },
    {
        page: 'Equipe',
        router: '/equipe',
    },
    {
        page: 'Sobre',
        router: '/sobre',
    },
    {
        page: 'Contato',
        router: '/contato',
    }
];