import { Paper } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import styled from "styled-components";

export const ContentPrinc = styled.div`  
  min-height: 350px;
  height: 100%;
  user-select: none;
  overflow: hidden;
  width: 100%;
  background: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentSec = styled.div`
  height: 100%;
  font-family: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const PaperAvatar = styled(Paper)`
  border-radius: 50%;
`;

export const ContentDesc = styled.div(({ theme }) => ({
  maxWidth: 550,
  padding: '0px 0px 30px 0px',
  color: '#fff',
  textAlign: 'center',
  letterSpacing: 1,
  [theme.breakpoints.down('xs')]: {
    maxWidth: 450
  },
  // [theme.breakpoints.up('sm')]: {
  //   maxWidth: 500
  // }
}));

export const DescName = styled.div`
  padding-bottom: 8px;
  text-align: center;
  color: #fff;
`;

export const DescCargo = styled.div`
  padding-bottom: 8px;
  text-transform: uppercase;
  color: #fff;
  max-width: 300px;
`;

export const StarComp = styled(Star)`
  height: 25px;
  width: 25px;
  color: #ffb400;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

export const ContentSocialInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;