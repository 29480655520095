import { IPlayListMaterData } from "./MateriasItem/Materias/interface";

export const playListMaterData: IPlayListMaterData[] = [
    {
        emphasis: true,
        title: "Conteúdos em destaque",
        link: "https://eeuqtyd7jz5nvzwzwb5g2yqdqa0bhkqf.lambda-url.sa-east-1.on.aws"
    },
    {
        title: "Histórias",
        link: "https://2czay5gbbx7zpqvpzanem2t2uq0vvgoo.lambda-url.sa-east-1.on.aws"
    },
    {
        title: "Instruções",
        link: "https://falzrj33bqa2trfwq4zvtpvq7i0gbdbo.lambda-url.sa-east-1.on.aws"
    },
    {
        title: "Tendências do mercado",
        link: "https://4qceteeefqslbpvgwmlohzutru0ysuxx.lambda-url.sa-east-1.on.aws"
    },
]