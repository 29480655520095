import { createTheme } from "@material-ui/core";

export const fontMazzartR = "'Mazzard M', sans-serif";
export const fontMazzartB = "'Mazzard M Bold', sans-serif";
export const fontMazzartSemiBold = "'Mazzard M Semi-Bold', sans-serif";
export const fontMazzartLight = "'Mazzard M Light', sans-serif";
export const fontMazzartMediumL = "'Mazzard Medium L', sans-serif";
export const fontMazzartLBold = "'Mazzard L Bold', sans-serif";


export const theme = createTheme({
    typography: {
        fontFamily: fontMazzartR,
        button: {
            fontWeight: 800
        }
    },
    palette: {
        primary: {
            main: "#ffffff",
            light: '#ffdb21',
            dark: '#f5b700'
        },
        secondary: {
            main: "#4d9b31",
            dark: "#004500ff",
            light: "#4caf50"
        },
        background: {
            default: "#111111",
            paper: 'rgb(22, 22, 22)',
        },        
    },
    transitions: {
        duration: {
            standard: 300
        }
    }
});