import ReactPlayer from "react-player";
import styled from "styled-components";

export const IFrameStyle = styled(ReactPlayer)(({ theme }) => ({
    border: 0,
    height: "100vh",
    width: "100%",
    // filter: "brightness(60%)",
    transform: "scale(1)",
    [theme.breakpoints.down('xl')]: {
        transform: "scale(2)"
    },
    [theme.breakpoints.down('lg')]: {
        transform: "scale(2)"
    },
    [theme.breakpoints.down('md')]: {
        transform: "scale(2)"
    },
    [theme.breakpoints.down('sm')]: {
        transform: "scale(2.7)"
    },
    [theme.breakpoints.down('xs')]: {
        transform: "scale(4)"
    },
}));

export const ContentScale = styled.div(({ theme }) => ({
    border: 0,
    height: "100vh",
    width: "100%",
    // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.6))',
    transform: "scale(1)",
    [theme.breakpoints.down('xl')]: {
        transform: "scale(1.1)"
    },
    [theme.breakpoints.down('lg')]: {
        transform: "scale(1.4)"
    },
    [theme.breakpoints.down('md')]: {
        transform: "scale(1.9)"
    },
    [theme.breakpoints.down('sm')]: {
        transform: "scale(3)"
    },
    [theme.breakpoints.down('xs')]: {
        transform: "scale(4)"
    },
}));