import { motion } from 'framer-motion';
import { CardActionArea, Typography } from "@material-ui/core";
import styled from "styled-components";

export const ButtonComp = styled(Typography)`
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  padding: 8px 18px;
  border-radius: 5px;
  background-color: #fff;
  color: black;
  border: 1px solid #fff;  
  font-family: inherit;
  letter-spacing: 1px;
  font-weight: 600;
`;


export const ContentImgLeft = styled(motion.div)`
  cursor: ${(props) => (props.mobile ? "pointer" : "")};
  /* border-right: ${(props) => (props.borderRight ? "1px solid #a1a1a1" : "0")}; */
  z-index: 1;
  height: inherit;
  width: ${(props) => (props.mobile ? "100%" : "50%")};
  &:hover {
    ${ButtonComp} {
      background-color: #fff;
      color: black;
    }
  }
`;

export const ContentButton = styled(motion.div)`
  color: #fff;
  display: flex; 
  align-items: center;
  justify-content: center; 
  z-index: 5;
  margin: 2px;
`;

export const ContentDesc = styled(motion.div)`
  color: #fff;
  display: flex; 
  height:100%;
  width:100%;
  align-items: center;
  justify-content: center; 
`;

export const ContentHover = styled(motion.div)`
  height: inherit; 
  width: 100%;
  transition: ease 0.3s;
`;

export const DescBase = styled(motion.div)`  
  text-align: center;
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};  
`;
