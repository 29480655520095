import { IThumbnails } from "../interface";

export function returnImgResolution(AThumbnails: IThumbnails): string {
    if (AThumbnails.maxres === undefined) {
        if (AThumbnails.standard === undefined) {
            if (AThumbnails.hight === undefined) {
                if (AThumbnails.medium === undefined) {
                    if (AThumbnails.default === undefined) {
                        return ''; //
                    } else {
                        return AThumbnails.default.url;
                    }
                } else {
                    return AThumbnails.medium.url;
                }
            } else {
                return AThumbnails.hight.url;
            }
        } else {
            return AThumbnails.standard.url;
        }
    } else {
        return AThumbnails.maxres.url;
    }
}