// const C_ABOUT_IMG =
//   "https://images.unsplash.com/photo-1472396961693-142e6e269027?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1806&q=80";
// "https://images.ctfassets.net/wds1hqrprqxb/1NT0z1bHV51RXreYBZiCBp/0fe6cce2525c937cf333ebd0e26bca63/f41c99227efbb995de1ccad2c66d841a29582a11.png?w=1900&h=1267&q=95&fm=webp";
const C_FIRST_DESC =
  "É um programa televisivo que chegou para evidenciar a agricultura da região, mostrando o protagonismo das famílias que vivem no campo, bem como de empresas que movimentam a economia regional e do Brasil.";
const C_SEC_DESC =
  "O programa é exibido na TV Sudoeste afiliada à RedeTV, aos domingos, às 10h, tem reprise nas segundas-feiras, às 13h10. São mais de 600 mil telespectadores (região Sudoeste, Oeste de Santa Catarina e fronteira com a Argentina).";
const C_SEC_IMG = 'https://d13x8u4oijb9vw.cloudfront.net/tvsudoeste-about.png';
const C_THIR_DESC = C_FIRST_DESC;

const C_FOUR_DESC =
  "O Bom Dia Rural é pra você, que se identifica com a agricultura ou que gosta de boas histórias, pois sabemos que tudo começa no campo e reconhecemos a importância que o agro tem em nossa vida.";
const C_FOUR_NAME = "- Equipe do Bom Dia Rural.";

export default {
  aboutTitle: "Sobre o Bom Dia Rural",
  // aboutImage: C_ABOUT_IMG,
  firstDesc: C_FIRST_DESC,
  secDescTitle: "Nossa emissora",
  secDesc: C_SEC_DESC,
  secImg: C_SEC_IMG,
  thirDesc: C_THIR_DESC,
  fourDesc: C_FOUR_DESC,
  fourName: C_FOUR_NAME
};
