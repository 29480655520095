import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ props, typography, palette, breakpoints }) => ({
    legalLink: {
        ...typography.caption,
        justifyContent: "center",
        color: palette.type === "dark" ? "#fff" : palette.text.secondary,
        position: "relative",
        [breakpoints.up("sm")]: {
            "&:not(:first-of-type)": {
                "&:before": {
                    content: '"|"',
                    display: "block",
                    position: "absolute",
                    left: 0
                }
            }
        }
    },
    newsletter: {
        fontSize: typography.caption.fontSize
    },
    navMenu: {
        flexWrap: "wrap"
    },
    boxNavManu: {
        display: "flex",
        flexDirection: "column",
        [breakpoints.up("sm")]: {
            flexDirection: "row"
        }
    },
    boxNavTermos: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        [breakpoints.down("sm")]: {
            justifyContent: "center"
        }
    },
    itemSobre: {
        fontSize: 10,
        cursor: "pointer",
        display: "flex",
        padding: "8px 16px",
        transition: "all 0.2s ease-out 0s",
        alignItems: "center",
        flexShrink: "0",
        borderRadius: "4px",
        textDecoration: "none",
        letterSpacing: "1px",
        fontWeight: 500,
        minWidth: '100%',
        [breakpoints.down("sm")]: {
            minWidth: 160,
        }
    },
    fontBtn: {
        textTransform: "lowercase",
        fontWeight: 300,
        fontSize: 20,
        letterSpacing: "1px",
    },
    btnFooter: {
        padding: 0,
    },
    btnSocial: {
        marginLeft: 7,
        "&:hover": {
        }
    },
    contentSm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    contentSecSm: {
        width: '100%',
        maxWidth: 270,
        borderRadius: 9,
    },
    contentTSm: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 5
    },
    contentLogo: {
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
    },
    contentSocialSm: {
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    contentSocial: {
        flexGrow: 1,
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    contentSmUp: {
        display: "flex",
        flexWrap: "wrap",
        padding: 10
    },
    contentSecSmUp: {
        flexGrow: 2
    },
    boxNavTermosSec: {
        borderRadius: 9,
        padding: 9
    },
    contentBtn: {
        paddingTop: 5,
        display: "flex",
        flexWrap: 'wrap'
    },
    content: {
        backgroundColor: palette.background.default,
        width: "100%",
        height: "100%"
    }
}));
