import { Button } from "@material-ui/core"
import styled from "styled-components";

import { IMyButton } from "./interface";

// export const MyButton = styled(Button) <IMyButton>`
//     background-color: ${props => props.bgcolorselect},
//     /* color: colortypo || "#fff",
//     borderColor: "#fff",
//     fontWeight: myfontweight,

//     "&:hover": {
//         backgroundColor: bgcolorhover,
//         borderColor: "#fff",
//         color: colorhover,
//         fontWeight: fontweighthover || 0,
//     },
//     transition: "ease 0.3s" */
//     `;


export const MyButton = styled(Button)<IMyButton>(({ bgcolorhover, colorhover, bgcolorselect, colortypo, fontweighthover, myfontweight }) => ({
  backgroundColor: bgcolorselect,
  color: colortypo || "#fff",
  borderColor: "#fff",
  // fontWeight: myfontweight,
  letterSpacing: 1.2,

  "&:hover": {
    backgroundColor: bgcolorhover,
    borderColor: "#fff",
    color: colorhover,
    // fontWeight: fontweighthover || 0,
  },
  transition: "ease 0.3s"
}))