import styled from "styled-components";

export const ContentMw = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: 500,
    [theme.breakpoints.down('sm')]: {
        maxWidth: 650
    }
}));

export const ContentFlexCenter = styled.div(({ }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
}));