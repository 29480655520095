import React from "react";

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Foot from "../Components/Foot";

import Principal from "../Components/Principal";

import GridMater from "../Components/GridMater";

import GridColab from "../Components/GridColab";
import ColabItem from "../Components/GridColab/ColabItem";

import GridEquipe from "../Components/GridEquipe";
import EquipeItem from "../Components/GridColab/ColabItem";

import { dataColab } from "../Components/GridColab/data.js";
import dataEquip from "../Components/GridEquipe/data.js";

import About from "../Components/About";
import MyNavbar from "../Components/MyNavbar";
import { MainProvider } from "../Contexts/main";
import MySwipeableDrawer from "../Components/MySwipeableDrawer";
import Contato from "../Components/Contact";
import MyLoading from "../Components/MyLoading";
import MyHelmet from "../Components/MyHelmet";
import MateriasYou from "../Components/MateriasYou";

export default function ReactRouter() {

  return (
    <Router >
      <MainProvider>
        <MyHelmet />
        <MyLoading />
        <MyNavbar />
        <Contato />
        <MySwipeableDrawer />
        <Switch >

          <Route
            path="/"
            exact
            component={() => <Principal />}
          />
          <Route
            path="/materias/:id"
            component={(e) => <MateriasYou />}
          />
          <Route
            path="/materias"
            component={() => <GridMater />}
          />
          <Route
            path="/patrocinadores/:id"
            component={(e) => (
              <ColabItem
                data={dataColab}
                namePath="Patrocinadores"
                descButton="Todos os Patrocinadores"
                pathName="/patrocinadores"
              />
            )}
          />
          <Route
            path="/patrocinadores"
            component={() => <GridColab />}
          />
          <Route
            path="/equipe/:id"
            component={() => (
              <EquipeItem
                data={dataEquip}
                descButton="Toda a Equipe"
                namePath="Equipe"
                pathName="/equipe"
              />
            )}
          />
          <Route
            path="/equipe"
            component={() => <GridEquipe />}
          />
          <Route
            path="/sobre"
            component={() => <About />}
          />
          <Route path="*" children={<Redirect to="/" />} />
        </Switch>
        <Foot />

      </MainProvider>
    </Router>
  );
}
