const name1 = "Denise Chiapetti Adamchuk";
const desc1 = "Bom dia Rural é um programa moderno, diversificado, que valoriza a nossa gente e as vocações produtivas regionais.";
const cargo1 = "Médica Veterinária e Chefe do núcleo regional da SEAB de Francisco Beltrão";

const name2 = "Gabriel Pandini";
const desc2 = "O Bom dia Rural vem abastecendo o Agro de boas informações, aproximando de novas tecnologias e modelos de produções, associados à inovação. Informações de qualidade apoiando a Produção Agrícola.";
const cargo2 = "Gerente de Agência - Cresol Tradição";

const name3 = "Roseli Piekas Capra";
const desc3 = "O Programa divulga o trabalho agrícola, mostrando sempre projetos inovadores que fazem a diferença no campo e na cidade. Mostrando as potencialidades de uma região onde a Agricultura Familiar faz a diferença.";
const cargo3 = "Agricultora, Produtora de Queijo";

const name4 = "Roseli Martinazzo";
const desc4 = "Um programa esclarecedor e de muita aprendizagem. Contribui na divulgação e informação de cada setor. É um meio de comunicação de fácil acesso, rápido e esclarecedor.";
const cargo4 = "Agricultora, Produtora de Queijo";

const name5 = "Franciele Pertile";
const desc5 = "O Programa Bom Dia Rural é um incentivo para nós, ajudando na divulgação de nossos produtos e nos mantendo informados das novidades!";
const cargo5 = "Agricultora";

export const data = [
  {
    desc: desc1,
    name: name1,
    cargo: cargo1
  },
  {
    desc: desc2,
    name: name2,
    cargo: cargo2
  },
  {
    desc: desc3,
    name: name3,
    cargo: cargo3
  },
  {
    desc: desc4,
    name: name4,
    cargo: cargo4
  },
  {
    desc: desc5,
    name: name5,
    cargo: cargo5
  }
];