import React from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import MyLottieImage from "../MyLottieImage";
import { IAnimationMain } from "./interface";
import { ItensLottieHtmlHome } from "../LottieHtml/data";

export default function MyAnimTextMain({ anim, animM, img, isMovie, title }: IAnimationMain) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box width={"100%"}>
            {isMovie && <ItensLottieHtmlHome />}
            {isMd ?
                <MyLottieImage title={title} isMovie={isMovie} maxwidth={false} anim={animM} img={img} />
                :
                <MyLottieImage title={title} isMovie={isMovie} maxwidth="lg" anim={anim} img={img} />
            }
        </Box>
    );
}
