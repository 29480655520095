import React from "react";
import { useTheme } from "@material-ui/core";
import { IMyTypographyDesc } from "./interface";
import MyFadeUp from "../../MyFadeUp";
import { TypographyDesc } from "./styles";

export default function MyTypographyDesc({ children, variant, style, color, fontFamily, fade, fontlg, fontmd, fontsm, fontxl, fontxs, component }: IMyTypographyDesc) {
    const theme = useTheme();
    return (
        <MyFadeUp fade={fade} >
            <TypographyDesc
                component={component ? component : "span"}
                fontFamily={fontFamily}
                style={style}
                variant={variant}
                color={color}
                theme={theme}
                fontxs={fontxs}
                fontsm={fontsm}
                fontmd={fontmd}
                fontlg={fontlg}
                fontxl={fontxl}
                children={children} />
        </MyFadeUp>

    )
}