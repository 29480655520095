import React, { createContext, useContext, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { ILottiePlayer, ILottiePlayerProps } from "./interface";
import { useLocation } from "react-router-dom";

const LottiePlayerContext = createContext<ILottiePlayer>({ openLottie: false, setOpenLottie: () => { }, isMovieDesk: false, setIsMovieDesk: () => { }, isImageDesk: false, setIsImageDesk: () => { }, isGif: false, setIsGif: () => { }, complete: false, setComplete: () => { } });

export const LottiePlayerProvider = ({ children, isMovie }: ILottiePlayerProps) => {
    const { pathname } = useLocation();
    const [openLottie, setOpenLottie] = useState(false);
    const [isMovieDesk, setIsMovieDesk] = useState(false);
    const [isImageDesk, setIsImageDesk] = useState(false);
    const [isGif, setIsGif] = useState(false);
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (pathname === "/") {
            if (isDesktop) {
                setIsMovieDesk(isMovie);
            } else {
                setIsGif(isMovie)
            }
        } else {
            setIsMovieDesk(false);
            setIsGif(false);
            setIsImageDesk(true);            
        }
    }, [isMovie]);

    return (
        <LottiePlayerContext.Provider
            value={{
                openLottie,
                setOpenLottie,
                isMovieDesk,
                setIsMovieDesk,
                isImageDesk,
                setIsImageDesk,
                isGif,
                setIsGif,
                complete,
                setComplete
            }}
        >
            {children}
        </LottiePlayerContext.Provider>
    )
}

export function useLottiePlayer() {
    const contextLottie = useContext(LottiePlayerContext);
    return contextLottie;
}