import React from "react";
import { useTheme } from "@material-ui/core";
import { IDescComp } from "./interface";
import { ContentDescAlign, ContainerMw, ContentSec } from "./styles";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";

export default function DescComp({ desc }: IDescComp) {
    const theme = useTheme();

    return (
        <ContainerMw>
            <ContentSec theme={theme} >
                <ContentDescAlign theme={theme} >
                    <MyTypographyDesc
                        fade
                        fontFamily="semi-bold"
                        component="h2"
                        color="primary"
                        fontxs={'1.60rem'}
                        fontsm={'1.90rem'}
                        fontmd={'1.90rem'}
                        fontlg={'1.90rem'}
                        fontxl={'1.90rem'}
                        style={{
                            color: theme.palette.primary.light,
                            textAlign: "center"
                        }}
                        children={"Bom Dia Rural"}
                    />
                    <MyTypographyDesc
                        fade
                        fontFamily="light"
                        color="primary"
                        component="p"
                        fontxs={theme.typography.body1.fontSize.toString()}
                        fontsm={theme.typography.h6.fontSize.toString()}
                        fontmd={theme.typography.h6.fontSize.toString()}
                        fontlg={theme.typography.h6.fontSize.toString()}
                        fontxl={theme.typography.h6.fontSize.toString()}
                        children={desc}
                    />
                </ContentDescAlign>
            </ContentSec>
        </ContainerMw>
    )
}