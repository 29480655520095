import { CardActionArea } from "@material-ui/core";
import styled from "styled-components";

const transition = "0.20s cubic-bezier(.6, .6, .6, .6)";

export const DivScreenshot = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;    
  transition: ${transition};
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Content = styled.div`
  z-index: 200;  
  padding: 20px 0px 23px 20px;
  // color: #004500;
`;

export const Title = styled.span`
  // display: block;  
  // transition: ${transition};
`;

export const Description = styled.span`
  display: block;
  transition: ${transition};  
`;

export const BottomBar = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: ${(props) => props.background && props.background};
  border-radius: 0 0 6px 6px;
  transition: ${transition};
`;

export const Style = styled.div(({ theme }) => ({
  padding: 0,  
  flexShrink: 0,
  width: '100%',
  textAlign: 'left',
  backgroundColor: '#fff',
  borderRadius: 9,
  cursor: 'pointer',
  transition: transition,
  marginBottom: 0,
  [theme.breakpoints.down('xs')]: {
    marginBottom: 16
  }
}));

// export const Style = styled.div`  
//   padding: 0;  
//   position: relative;
//   flex-shrink: 0;
//   width: ${cardWidth};
//   text-align: left;
//   background-color: #fff;
//   border-radius: ${borderRadius}px;
//   cursor: pointer;
//   transition: ${transition};
//   /* &:hover {     

//     ${Title},
//     ${Description} {
//       transform: translateY(-0px) translateX(-5px);
//     }   

//     ${BottomBar} {
//       border-radius: ${borderRadius - 2}px;
//       transform: translateY(-7px) scale(0.94);
//     }

//     ${DivScreenshot} {
//       transform: scale(1.01);      
//     }    

//   } */
// `;

export const CardActionAreaComp = styled(CardActionArea)`
  border-radius: 9px;
  color: ${props => props.bgcolor};
`;