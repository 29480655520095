import { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, useTheme } from "@material-ui/core";
import { animate, motion, useAnimation } from "framer-motion";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import { ContentButton, ContentDesc, ContentHover, ContentImgLeft } from "./styles";
import { CardActionAreaComp, DescContent, DescTitle } from "./styles.ts";
import MyLink from "../../MyLink";
import { transition } from "./data";

export default memo(function CardUp(props) {
  const theme = useTheme();
  const history = useHistory();
  const itemBtnControls = useAnimation();
  const itemDescControls = useAnimation();

  const isleft = props.isleft ? true : false;
  const ismid = props.ismid ? true : false;

  useEffect(() => {
    if (props.mobile) {
      sequenceUp();
    }
  }, [props.mobile]);

  const sequenceUp = async () => {
    if (!props.mobile) props.setImage(isleft ? 1 : ismid ? 2 : 3);

    itemDescControls.stop();
    itemBtnControls.stop();

    await itemDescControls.start({
      opacity: 1,
      x: 0
    });

    await itemBtnControls.start({
      opacity: 1,
      x: 0
    });
  };

  const sequenceDown = async () => {
    if (props.mobile) return;

    itemDescControls.stop();
    itemBtnControls.stop();

    itemDescControls.start({ opacity: 0 });
    await itemBtnControls.start({ opacity: 0 });
    await itemBtnControls.start({ x: 15 });
    await itemDescControls.start({ x: 15 });
  };

  function setarRouter() {
    setTimeout(() => {
      history.push(props.pathName);
    }, 300);
  }

  return (
    <ContentImgLeft
      initial={props.mobile ? sequenceUp : sequenceDown}
      mobile={props.mobile}
      borderRight={props.borderRight}
      onHoverStart={props.mobile ? "" : sequenceUp}
      onHoverEnd={props.mobile ? "" : sequenceDown}
    >
      <CardActionAreaComp theme={theme} isleft={isleft ? 1 : 0} ismid={ismid ? 1 : 0} onClick={() => setarRouter()}>
        <MyLink href={props.pathName}>
          <ContentHover>
            <ContentDesc>
              <motion.div style={{ minHeight: props.mobile ? 0 : 250 }}>
                <DescTitle theme={theme} >
                  <MyTypographyDesc
                    fontFamily="bold"
                    component="h3"
                    style={{ color: theme.palette.primary.light, letterSpacing: 1.5, textAlign: "center" }}
                    // fontxs={theme.typography.h5.fontSize.toString()}
                    // fontsm={theme.typography.h4.fontSize.toString()}
                    // fontmd={'1.9rem'}
                    // fontlg={theme.typography.h4.fontSize.toString()}
                    // fontxl={theme.typography.h4.fontSize.toString()}
                    fontxs={theme.typography.h5.fontSize.toString()}
                    fontsm={theme.typography.h4.fontSize.toString()}
                    fontmd={theme.typography.h5.fontSize.toString()}
                    fontlg={theme.typography.h4.fontSize.toString()}
                    fontxl={theme.typography.h4.fontSize.toString()}
                    children={props.title}
                  />
                </DescTitle>
                <motion.div>
                  <DescContent
                    ismid={ismid}
                    theme={theme}
                    initial={{ y: 0, opacity: 0 }}
                    animate={itemDescControls}
                    transition={transition}
                    children={
                      <MyTypographyDesc
                        fontFamily="light"
                        component="p"
                        fontxs={theme.typography.body1.fontSize.toString()}
                        fontsm={theme.typography.h6.fontSize.toString()}
                        fontmd={theme.typography.body1.fontSize.toString()}
                        fontlg={theme.typography.h6.fontSize.toString()}
                        fontxl={theme.typography.h6.fontSize.toString()}
                        children={props.desc}
                      />
                    }
                  />
                  <ContentButton
                    style={{ zIndex: 5 }}
                    initial={{ y: 0, opacity: 0 }}
                    animate={itemBtnControls}
                    transition={transition}
                  >
                    <Box children={
                      <MyTypographyDesc
                        fontFamily="regular"
                        component="p"
                        style={{ color: theme.palette.primary.light, opacity: .8, letterSpacing: 1 }}
                        fontxs={theme.typography.body1.fontSize.toString()}
                        fontsm={theme.typography.h6.fontSize.toString()}
                        fontmd={'1.1rem'}
                        fontlg={theme.typography.h6.fontSize.toString()}
                        fontxl={theme.typography.h6.fontSize.toString()}
                        children={props.btnDesc}
                      />
                    } />
                  </ContentButton>
                </motion.div>
              </motion.div>
            </ContentDesc>
          </ContentHover>
        </MyLink>
      </CardActionAreaComp>
    </ContentImgLeft>
  );
});
