import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CardComp from "./Card";
import { useEffect, useState } from "react";
import iCard from "./interface";
import objCard from "./data.js";
import { GridContainer, Griditem } from "./styles";

export default function DoubleCard() {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('xs'));
    const [dataCard, setDataCard] = useState<iCard[]>([]);

    useEffect(() => {
        setDataCard(objCard);
    }, []);

    return (
        <GridContainer container spacing={isSm ? 0 : 2}>
            {dataCard.map((e, count) => (
                <Griditem theme={theme} key={count} item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <CardComp datacard={e} />
                </Griditem>
            ))}
        </GridContainer>
    );
}
