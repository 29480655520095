const imgJean = "https://d13x8u4oijb9vw.cloudfront.net/jean-campanhollo.jpeg";
const imgEduardo = "https://d13x8u4oijb9vw.cloudfront.net/eduardo-britto.jpeg";
const imgBaggio = "https://d13x8u4oijb9vw.cloudfront.net/equipe-baggio.jpg";
const imgCarine = "https://d13x8u4oijb9vw.cloudfront.net/equipe-carine.jpg";
const imgTarcisio = "https://d13x8u4oijb9vw.cloudfront.net/equipe-tarcisio.jpg";
const imgIndianaraPaes = "https://d13x8u4oijb9vw.cloudfront.net/equipe-indi.jpg";
const imgDarceAlmeida = "https://d13x8u4oijb9vw.cloudfront.net/equipe-darce.jpg";
const imgLeandroCzerniaski = "https://d13x8u4oijb9vw.cloudfront.net/leandro-czerniaski.jpeg";

const desc1 = "";
const desc2 = "";

export default [
  {
    search: "jean-campanhollo",
    nome: "Jean",
    sobreNome: "Campanhollo",
    cargo: "Diretor",
    descricao1: desc1,
    descricao2: desc2,
    img: imgJean,
    hexa: "#4e9b31",
    social: {
      facebook: 'jean.campanhollo',
      instagram: 'jeancampanhollo'
    }
  },
  {
    search: "eduardo-britto",
    nome: "Eduardo",
    sobreNome: "Britto",
    cargo: "Diretor",
    descricao1: desc1,
    descricao2: desc2,
    img: imgEduardo,
    hexa: "#4e9b31",
    social: {
      facebook: 'profile.php?id=100077053179021',      
      instagram: 'edubritto.e'
    }
  },
  {
    search: "tarcisio-Reinehr",
    nome: "Tarcísio",
    sobreNome: "Reinehr",
    cargo: "Diretor",
    descricao1: desc1,
    descricao2: desc2,
    img: imgTarcisio,
    hexa: "#4e9b31",
    social: {
      facebook: 'tarcisio.reinehr.1',
      instagram: 'tarcisioreinehr'
    }
  },
  {
    search: "carine-prolo",
    nome: "Carine",
    sobreNome: "Prolo",
    cargo: "Apresentadora",
    descricao1: desc1,
    descricao2: desc2,
    img: imgCarine,
    hexa: "#4e9b31",
    social: {
      facebook: 'carine.prolo',
      instagram: 'carineprolo'
    }
  },
  {
    search: "luiz-baggio",
    nome: "Luiz",
    sobreNome: "Baggio",
    cargo: "Jornalista",
    descricao1: desc1,
    descricao2: desc2,
    img: imgBaggio,
    hexa: "#4e9b31",
    social: {
      facebook: 'luizcarlos.baggio.58',
      instagram: 'luizcarlosbaggio'
    }
  },
  {
    search: "indianara-paes",
    nome: "Indianara",
    sobreNome: "Paes",
    cargo: "Jornalista",
    descricao1: desc1,
    descricao2: desc2,
    img: imgIndianaraPaes,
    hexa: "#4e9b31",
    social: {
      facebook: 'indianarapaes',
      instagram: 'indipaes'
    }
  },
  {
    search: "darce-almeida",
    nome: "Darce",
    sobreNome: "Almeida",
    cargo: "Jornalista",
    descricao1: desc1,
    descricao2: desc2,
    img: imgDarceAlmeida,
    hexa: "#4e9b31",
    social: {
      facebook: 'darcealmeida',
      instagram: 'darcealmeida_jornalista'
    }
  },
  {
    search: "leandro-czerniask",
    nome: "Leandro",
    sobreNome: "Czerniask",
    cargo: "Jornalista",
    descricao1: desc1,
    descricao2: desc2,
    img: imgLeandroCzerniaski,
    hexa: "#4e9b31",
    social: {
      facebook: 'leandro.czerniaski',
      instagram: '_czerniaski'
    }
  }
];
