import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Box,
  CardActions,
  IconButton,
  Button,
  useTheme,
  Link,
} from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import ArrowBack from "@material-ui/icons/ArrowBack";
import MyPressMotion from "../../MyPressMotion";
import { ContainerComp, ContentRelative, ContainerSecComp } from "../../GridMater/styles";
import { CardMediaComp, ContentBgColor, ContentMediaQ, BoxImage, BoxPrinc, ContentDesc, ContentDescription } from "./styles";
import MyFrameColab from "./MyFrameColab";
import MyImageLazzy from "../../MyImageLazzy";
import { loadImage } from "../../../Comum";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import MyLink from "../../MyLink";

export default function ColabItem(props) {
  const theme = useTheme();
  const data = props.data;
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dataColab, setDataColab] = useState({ data: null });

  useEffect(() => {
    const state = retornarState();
    state ? setDataColab({ data: state }) : history.push(props.pathName);
  }, []);

  function retornarState() {
    const dataReturn = data.find(data => data.search.includes(id));
    return dataReturn ? dataReturn : null;
  }

  // useEffect(() => {
  //   if (loading) {
  //     // window.scrollTo({ top: 0 });
  //   }
  // }, [loading]);

  useEffect(() => {
    if (dataColab.data) {
      if (dataColab.data.link) {
        setLoading(false)
      } else {
        setLoadImg()
      }
    }
  }, [dataColab]);

  const setLoadImg = () => loadImage({ onLoad: setLoading(false), src: returnSrcImg() });

  function setarRouter() {
    setTimeout(() => {
      history.push(props.pathName);
    }, 300);
  }

  const returnSrcImg = () => dataColab.data.imgItem ? dataColab.data.imgItem : dataColab.data.img;

  const ItensHtmlSemantic = () => (
    <span style={{ opacity: 0, position: "absolute" }} >
      <h1>{dataColab.data.nome + " " + dataColab.data.sobreNome}</h1>
    </span>
  )

  function retornarConteudo(ADataColab) {
    if (loading) return false
    return (

      <ContentRelative>
        <ItensHtmlSemantic />
        <ContainerComp>
          <ContainerSecComp>
            <BoxPrinc theme={theme} >
              <div
                data-aos="fade-left"
                data-aos-delay="0"
                style={{ width: "100%" }}
              >
                <Box>
                  <Box display="flex" paddingLeft="0px" marginBottom="10px">
                    <MyLink href={props.pathName}>
                      <Button color={"primary"} onClick={() => setarRouter()} style={{ letterSpacing: "1px" }}>
                        <ArrowBack style={{ height: 15, width: 15 }} />
                        <Box margin="2px 9px 0px 9px">
                          <MyTypographyDesc
                            fade={false}
                            fontFamily="medium-l"
                            color="primary"
                            fontxs={theme.typography.caption.fontSize.toString()}
                            fontsm={theme.typography.caption.fontSize.toString()}
                            fontmd={theme.typography.caption.fontSize.toString()}
                            fontlg={theme.typography.caption.fontSize.toString()}
                            fontxl={theme.typography.caption.fontSize.toString()}
                            children={props.descButton}
                          />

                        </Box>
                      </Button>
                    </MyLink>
                  </Box>
                  <Box style={{ textTransform: "uppercase" }} >
                    <MyTypographyDesc
                      fade={false}
                      fontFamily="bold"
                      color="primary"
                      fontxs={'10vw'}
                      fontsm={theme.typography.h2.fontSize.toString()}
                      fontmd={theme.typography.h2.fontSize.toString()}
                      fontlg={theme.typography.h2.fontSize.toString()}
                      fontxl={theme.typography.h2.fontSize.toString()}
                      style={{
                        lineHeight: 1.1,
                        letterSpacing: 1,
                        color: theme.palette.primary.light
                      }}
                      children={ADataColab.nome}
                    />

                  </Box>
                  <Box style={{ textTransform: "uppercase" }}>
                    <MyTypographyDesc
                      fade={false}
                      fontFamily="bold"
                      color="primary"
                      fontxs={'10vw'}
                      fontsm={theme.typography.h2.fontSize.toString()}
                      fontmd={theme.typography.h2.fontSize.toString()}
                      fontlg={theme.typography.h2.fontSize.toString()}
                      fontxl={theme.typography.h2.fontSize.toString()}
                      style={{
                        lineHeight: 1.1,
                        letterSpacing: 1,
                        color: theme.palette.primary.light
                      }}
                      children={ADataColab.sobreNome}
                    />

                  </Box>
                  <ContentDesc theme={theme} >
                    <MyTypographyDesc
                      fade={false}
                      fontFamily="regular"
                      component="h2"
                      color="primary"
                      fontxs={theme.typography.h6.fontSize.toString()}
                      fontsm={theme.typography.h6.fontSize.toString()}
                      fontmd={theme.typography.h6.fontSize.toString()}
                      fontlg={theme.typography.h6.fontSize.toString()}
                      fontxl={theme.typography.h6.fontSize.toString()}
                      style={{ letterSpacing: 1, paddingRight: 10, opacity: 0.9 }}
                      children={ADataColab.cargo}
                    />

                  </ContentDesc>
                  <Box height="50px" marginBottom="10px">
                    <CardActions style={{ padding: "0px" }}>
                      <Link href={`https://www.facebook.com/${ADataColab.social.facebook}`} target="_blank" >
                        <IconButton color={"primary"} >
                          <FacebookIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                      </Link>
                      <Link href={`https://www.instagram.com/${ADataColab.social.instagram}`} target="_blank" >
                        <IconButton color={"primary"} >
                          <InstagramIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                      </Link>
                    </CardActions>
                  </Box>
                  <ContentDescription theme={theme} >
                    <Box marginBottom="8px" >
                      <MyTypographyDesc
                        fade={false}
                        fontFamily="light"
                        color="primary"
                        component="p"
                        fontxs={theme.typography.body1.fontSize.toString()}
                        fontsm={theme.typography.h6.fontSize.toString()}
                        fontmd={theme.typography.h6.fontSize.toString()}
                        fontlg={theme.typography.h6.fontSize.toString()}
                        fontxl={theme.typography.h6.fontSize.toString()}
                        style={{ letterSpacing: 1 }}
                        children={ADataColab.descricao1}
                      />
                    </Box>
                  </ContentDescription>
                </Box>
              </div>

              <BoxImage theme={theme} >
                <MyPressMotion onClick={() => { }} >
                  <Box
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <Box
                      style={{
                        overflow: "hidden",
                        borderRadius: 9,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      {
                        dataColab.data.link
                          ?
                          <MyFrameColab animOpIntro url={dataColab.data.link} />
                          :
                          <CardMediaComp theme={theme}>
                            <MyImageLazzy
                              title={ADataColab.nome + " " + ADataColab.sobreNome}
                              disableBackgroundLinear
                              isEquipe
                              image={returnSrcImg()}
                            />
                          </CardMediaComp>
                      }
                    </Box>
                  </Box>
                </MyPressMotion>
              </BoxImage>
            </BoxPrinc>
          </ContainerSecComp>
        </ContainerComp >
      </ContentRelative >
    );
  }
  return (
    <main>
      <section>
        <ContentBgColor theme={theme}>
          <ContentMediaQ theme={theme}>
            {retornarConteudo(dataColab.data)}
          </ContentMediaQ>
        </ContentBgColor>
      </section>
    </main>
  );
}
