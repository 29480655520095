import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import styled from "styled-components";

export const IFrameStyle = styled(ReactPlayer)(({ theme }) => ({
    border: 0,
    height: '100%',
    width: '100%',
    borderRadius: 9
    // height: 500, 
    // width: 700    
    // filter: "brightness(60%)",
    // transform: "scale(1)",
    // [theme.breakpoints.down('xl')]: {
    //     transform: "scale(2)"
    // },    
    // [theme.breakpoints.down('lg')]: {
    //     transform: "scale(2)"
    // },
    // [theme.breakpoints.down('md')]: {
    //     transform: "scale(2)"
    // },
    // [theme.breakpoints.down('sm')]: {
    //     transform: "scale(2.7)"
    // },
    // [theme.breakpoints.down('xs')]: {
    //     transform: "scale(4)"
    // },
}));

export const ContentFrameColab = styled(motion.div)(({ theme }) => ({
    borderRadius: 9,
    // backgroundColor: theme.palette.background.paper,
    // height: 500,
    width: '100%',
    [theme.breakpoints.down('xl')]: {
        height: 500,
        // width: 800,
    },
    [theme.breakpoints.down('lg')]: {
        height: 500,
        // width: '50vw',        
    },
    [theme.breakpoints.down('md')]: {
        height: 500,
        // width: '50vw',
    },
    [theme.breakpoints.down('sm')]: {
        height: 450,
        // width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
        height: 400,
        // width: '100%',
    },
}));
