import React from "react";
import { useTheme } from "@material-ui/core";
import iProps from "./interface";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import { ContentDesc, ContentItemDesc, ContentPrinc } from "./styles";

export default function DescCard(props: iProps) {
  const theme = useTheme();
  return (
    <ContentPrinc>
      <ContentDesc>
        <ContentItemDesc>
          <MyTypographyDesc
            fade={false}
            fontFamily="semi-bold"
            component="h2"
            color="primary"
            fontxs={'6vw'}
            fontsm={'28px'}
            fontmd={'28px'}
            fontlg={'28px'}
            fontxl={'28px'}
            style={{
              color: theme.palette.primary.main,
              letterSpacing: 1,
              lineHeight: 1.1
            }}
            children={props.datacard.buttonDesc}
          />
          <MyTypographyDesc
            fade={false}
            fontFamily="bold"
            component="h2"
            color="primary"
            fontxs={"8vw"}
            fontsm={theme.typography.h3.fontSize.toString()}
            fontmd={theme.typography.h3.fontSize.toString()}
            fontlg={theme.typography.h3.fontSize.toString()}
            fontxl={theme.typography.h3.fontSize.toString()}
            style={{
              color: "#fbd943",
              letterSpacing: 1,
              textAlign: "center",
            }}
            children={props.datacard.desc}
          />
        </ContentItemDesc>
      </ContentDesc>
    </ContentPrinc>
  );
}
