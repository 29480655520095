import styled from "styled-components";

export const ContentArrow = styled.div(({ theme }) => ({
    width: 40,
    padding: 0,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 0,
    border: "0px",
    opacity: 1,
    userSelect: "none",
    "&:hover": {
        [ContentArrowIcon]: {
            transform: "scale(1.1)"
        }
    }
}));

export const ContentArrowIcon = styled.div(({ theme }) => ({
    transform: "scale(1)",
    transitionDuration: "0.2s",
    transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
}));