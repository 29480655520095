import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ContentBg = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default
}));

export const Content = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "60vh",
    paddingTop: 84,
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
        paddingTop: 66
    },
}));

export const ContentDescPrinc = styled(Box)(({ theme }) => ({
    width: "100%"
}));

export const ContentTitle = styled(Box)(({ theme }) => ({
    borderRadius: 9,
    marginTop: 10,
    marginBottom: 10,
    minHeight: 45,
    transition: "ease-in-out .2s",
    [theme.breakpoints.down("sm")]: {
        minHeight: 40,
    },
}));

export const ContentDesc = styled(Box)(({ theme }) => ({
    paddingTop: 5,
    backgroundColor: "#ffffff1a",
    borderRadius: 9,
    padding: 10,
    // height: 300,
    // overflow: "hidden"
}));

export const ContentMovie = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    width: "100%",
    borderRadius: 9
}));



