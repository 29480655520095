import styled from "styled-components";

export const ContentPrinc = styled.div`
position: absolute;
z-index: 1;
color: #fff;
margin-top: -350px;
user-select: none;
width: 100%;
`;

export const ContentDesc = styled.div`
height: 350px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export const ContentItemDesc = styled.div`
height: 150px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;