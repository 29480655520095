import { useTheme } from "@material-ui/core";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import { ContentDescAlign } from "../DescComp/styles";
import { ContainerTextLast, Content, ContentDesc } from "./styles";

export default function TextLast(props) {
  const theme = useTheme();
  return (
    <ContainerTextLast theme={theme}>
      <Content fontFamily={props.fontFamily}>
        <ContentDescAlign theme={theme} >
          <ContentDesc theme={theme} >
            <MyTypographyDesc
              fade
              fontFamily="light"
              component="p"
              color="primary"
              fontxs={theme.typography.body1.fontSize.toString()}
              fontsm={theme.typography.h6.fontSize.toString()}
              fontmd={theme.typography.h6.fontSize.toString()}
              fontlg={theme.typography.h5.fontSize.toString()}
              fontxl={theme.typography.h5.fontSize.toString()}
              children={props.desc}
            />
          </ContentDesc>
          <MyTypographyDesc
            fade
            fontFamily="regular"
            component="h3"
            fontxs={theme.typography.body2.fontSize.toString()}
            fontsm={theme.typography.body1.fontSize.toString()}
            fontmd={theme.typography.body1.fontSize.toString()}
            fontlg={theme.typography.h6.fontSize.toString()}
            fontxl={theme.typography.h6.fontSize.toString()}
            style={{ color: theme.palette.primary.light, textAlign: 'center', letterSpacing: 1, opacity: .9 }}
            children={props.name}
          />
        </ContentDescAlign>
      </Content>
    </ContainerTextLast>
  );
}
