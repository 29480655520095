import { Avatar } from "@material-ui/core";
import styled from "styled-components";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export const AvatarComp = styled(Avatar)`
  z-index: 5;
`;

export const ContentAvatarPrinc = styled.div`
  border: 3px solid transparent;
  background-color: #004500;
  border-radius: 50%;  
  margin-bottom: 5px;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.60, 0.60, 0.60, 0.60)
`;

export const ContentAvatar = styled.div`
  border: 4px solid transparent;
  border-radius: 50%;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.60, 0.60, 0.60, 0.60);
  z-index: 5;
`;

export const ContentSocial = styled.div`
  opacity: 1;
  z-index: -1;
  transform: translateY(40px);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.60, 0.60, 0.60, 0.60)
`;

export const ContentCircle = styled.div`
  height: 600px;
  width: 55%;
  border: 1px #000;
  border-radius: 50%;
  background-color: #004500;
  opacity: 1;
  z-index: -1;
  transform: translateY(-55px) scale(1.4);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.60, 0.60, 0.60, 0.60)
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  z-index: 5;
  align-items: center;
  height: 100%;
  width: 100%;
`;

interface IContentItem {
}

export const ContentItem = styled.div<IContentItem>`
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transform: translateY(-10px);
`;

export const FacebookIconComp = styled(FacebookIcon)`
  color: #004500;
`;

export const LinkedInIconComp = styled(LinkedInIcon)`
  color: #004500;
`;

export const InstagramIconComp = styled(InstagramIcon)`
  color: #004500;
`;

export const ContentDesc = styled.div`
  text-align: center;
`;

export const CardComp = styled.div`
  border: 2px solid #4caf50;
  background-color: #fff;
  user-select: none;
  overflow: hidden;
  border-radius: 5px;
  height: 300px;
  width: 100%;
  display: flex;
  z-index: 5;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.60, 0.60, 0.60, 0.60);
  @media(max-width: 599px) {
    margin-bottom: 16px;
    ${ContentAvatar} {
      border: 4px solid #4caf50;
      transform: scale(0.96);
    }
    ${ContentSocial} {
      opacity: 1;
      transform: translateY(5px);
    }
    ${ContentCircle} {
      opacity: 1;
      transform: translateY(30px) scale(3.5);
    }
  };
  &:hover {
    ${ContentAvatar} {
      border: 4px solid #4caf50;
      transform: scale(0.96);
    }
    ${ContentSocial} {
      opacity: 1;
      transform: translateY(5px);
    }
    ${ContentCircle} {
      opacity: 1;
      transform: translateY(30px) scale(3.5);
    }
  }
`;