import React from "react";
import { IArrow } from "../interface";
import { ContentArrow } from "../styles";

export default function Arrow({ children, disabled, onClick, isLeft }: IArrow) {
    return (
        <ContentArrow onClick={onClick} >
            {children}
        </ContentArrow>
    );
}