import React from "react";
import { H2Hidden } from "./styles";

export const ItensLottieHtmlDescPrincHome = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Conheça nosso conteúdo e equipe</h2>
  </span>
)

export const ItensLottieHtmlDescPrincColabHome = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Nossos Patrocinadores</h2>
  </span>
)

export const ItensLottieHtmlDescOpiniaoHome = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>o que dizem sobre nós</h2>
  </span>
)

export const ItensHomeChave = () => (
  <>
    <H2Hidden>Agricultura Paraná</H2Hidden>
    <H2Hidden>Agricultura</H2Hidden>
    <H2Hidden>Programa</H2Hidden>
    <H2Hidden>Programa Rural</H2Hidden>
    <H2Hidden>Programa de TV</H2Hidden>
    <H2Hidden>Sudoeste do Paraná</H2Hidden>
    <H2Hidden>Agricultura Sudoeste</H2Hidden>
    <H2Hidden>Sudoeste agro</H2Hidden>
    <H2Hidden>Produção</H2Hidden>
    <H2Hidden>Bovinocultura</H2Hidden>
    <H2Hidden>Bovinocultura</H2Hidden>
    <H2Hidden>Jornalismo</H2Hidden>
    <H2Hidden>TV Sudoeste Programa</H2Hidden>
    <H2Hidden>Programa Agro</H2Hidden>
    <H2Hidden>Bom Dia</H2Hidden>
    <H2Hidden>Notícias Paraná</H2Hidden>
  </>
)

export const ItensLottieHtmlHome = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h1>Bom Dia Rural</h1>
    <ItensHomeChave />
    <h2>O Programa de quem planta e produz.</h2>
    <h2>O Programa é exibido na TV Sudoeste, afiliada à RedeTV, todos os domingos de manhã, às 10 horas, com reprise nas segundas-feiras à tarde, às 13:30 horas.</h2>
  </span>
)

export const ItensLottieHtmlNossoProgramaHome = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Nosso programa todo domingo</h2>
    <h3>As 10:00h Na TV Sudoeste</h3>
    <h3>Matérias atualizadas semanalmente</h3>
    <h3>Conteúdo Agro. O agro é o nosso foco</h3>
  </span>
)

export const ItensLottieHtmlPrincMaterias = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h1>Toda semana matérias exclusivas.</h1>
    <h2>Reportagens jornalísticas que contam histórias, relatam experiências da vida rural, tendências do mercado, além das inovações tecnológicas aplicadas ao campo.</h2>
  </span>
)

export const ItensLottieHtmlDescPrincMaterias = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Nossas Matérias</h2>
  </span>
)

export const ItensHtmlLottieMainPatroc = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h1>Conheça nossos patrocinadores.</h1>
    <h2>Profissionais dedicados e comprometidos com o crescimento. Especialistas em suas áreas, fundamentais para o Bom Dia Rural.</h2>
  </span>
);

export const ItensHtmlLottieDescColabPatroc = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Nossos Patrocinadores</h2>
  </span>
);

export const ItensHtmlLottieMainEquipe = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h1>Conheça nossa equipe</h1>
    <h2>Contamos com um time de profissionais altamente qualificados e com experiência no jornalismo há mais de 10 anos.</h2>
  </span>
);

export const ItensHtmlLottieEquipeCapaEquipe = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h2>Nossa Equipe</h2>
  </span>
);

export const ItensHtmlLottieMainAbout = () => (
  <span style={{ opacity: 0, position: "absolute" }} >
    <h1>Sobre o Bom Dia Rural.</h1>
    <h2>Saiba mais sobre o programa que tem mais de 600 mil telespectadores e mais de 15 mil seguidores nas redes sociais.</h2>
  </span>
);