import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import MyTypographyDesc from "../../../MyTypography/MyTypographyDesc";
import { ContentCardMateria, ContentTypography, ContentMedia, ContentMediaImg, ContentPlayIcon, ContentMediaPrinc, ContentShadowHov, ContentShadow, PlayIcon, PauseIcon } from "../styles";
import { IMediaCard } from "./interface";
import { useAnimation, motion } from "framer-motion";
import { Skeleton } from "@material-ui/lab";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { transition } from "./data";
import { loadImage } from "../../../../Comum";
import { Content } from "./styles";

export default function MediaCard({ img, title, videoId, desc, id, openMovie, setOpenMovie }: IMediaCard) {
    const animMedia = useAnimation();
    const animShadowEnter = useAnimation();
    const theme = useTheme();
    const [isPan, setPan] = useState(false);
    const boxRef = useRef<HTMLDivElement>(null);
    const [myImg, setMyImg] = useState("");

    const visibilityC = useContext(VisibilityContext);
    const visibleItemPrev = visibilityC.isItemVisible((parseInt(id) - 1).toString());
    const visibleItem = visibilityC.isItemVisible(id);
    const visibleItemNext = visibilityC.isItemVisible((parseInt(id) + 1).toString());
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible((!visibleItemPrev && !visibleItem && !visibleItemNext) ? false : true);
    }, [visibleItemPrev, visibleItem, visibleItemNext]);

    useEffect(() => {
        hanldeSetOp();
    }, [img, isVisible]);

    useEffect(() => {
        handleLoadImg();
    }, [myImg]);

    const handleLoadImg = () => {
        if (myImg === '') {
            animOp(0).then(() => animShadow(0));
        } else {
            loadImage({ onLoad: animOp(1).then(() => animShadow(1)), src: myImg });
        }
    }

    const hanldeSetOp = async () => {
        if (!img) return;
        setMyImg(isVisible ? img : '');
    }

    const animOp = async (AOp: number) => await animMedia.start({ opacity: AOp });
    const animShadow = (AOp: number) => animShadowEnter.start({ opacity: AOp });

    const handleClick = () => {
        if (!isPan && myImg) {
            setOpenMovie(true);
        }
    };

    return (
        <Content ref={boxRef}>
            <ContentCardMateria
                play={openMovie}
                theme={theme}
                img={myImg}
                onPanStart={() => setPan(true)}
                onPanEnd={() => setTimeout(() => setPan(false), 300)}
                onClick={() => handleClick()}
            >
                <ContentMediaPrinc
                    theme={theme}
                >
                    {/* <ContentMedia
                        play={openMovie}
                        theme={theme}
                        img={myImg}
                        title={title}
                        initial={{ opacity: 0 }}
                        animate={animMedia}
                        transition={transition}
                    /> */}
                    <ContentMediaImg
                        alt={title}
                        src={myImg}
                        initial={{ opacity: 0 }}
                        animate={animMedia}
                        transition={transition}
                    />
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={animShadowEnter}
                        transition={transition}
                    >
                        <ContentPlayIcon>
                            <PlayIcon color="primary" />
                        </ContentPlayIcon>
                        <ContentPlayIcon>
                            <PauseIcon opacityicon={openMovie ? 1 : 0} color="primary" />
                        </ContentPlayIcon>
                        <ContentShadow />
                        <ContentShadowHov />
                    </motion.div>
                </ContentMediaPrinc>

                <ContentTypography istitle={title !== ''} >
                    <MyTypographyDesc
                        component="h3"
                        fontFamily="semi-bold"
                        style={{
                            color: theme.palette.primary.main,
                            letterSpacing: 1
                        }}
                        fontxs={theme.typography.body2.fontSize.toString()}
                        fontsm={theme.typography.body2.fontSize.toString()}
                        fontmd={theme.typography.body2.fontSize.toString()}
                        fontlg={theme.typography.body2.fontSize.toString()}
                        fontxl={theme.typography.body1.fontSize.toString()}
                        children={
                            title
                                ?
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={transition}
                                >
                                    {title}
                                </motion.div>
                                :
                                <>
                                    <Skeleton animation={false} style={{ backgroundColor: theme.palette.background.paper }} />
                                    <Skeleton animation={false} style={{ backgroundColor: theme.palette.background.paper }} />
                                </>
                        }
                    />
                </ContentTypography>

            </ContentCardMateria >
        </Content>
    );
}
