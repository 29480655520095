import { useState } from "react";
import { Hidden } from "@material-ui/core";
import CardUp from "./CardUp";
import MyMotionImgLazzy from "../MyMotionImgLazzy";
import MyFlexCenter from "../MyFlexCenter";
import { Content, ContentPrinc, ContentSm, ContentFade, ContentSec } from "./styles.js";
import data from "./data.js";
import MyFadeUp from "../MyFadeUp";

export default function CardHover() {
  const [image, setImage] = useState(data.image[0]);
  const setImageChange = (e) => setImage(data.image[e - 1]);;

  function retornarComp() {
    return (
      <ContentPrinc image={image}>
        <ContentSec>
          <ContentFade>
            <MyFadeUp fade>
              <Hidden smDown>
                <Content>
                  <CardUp
                    key="0"
                    isleft
                    mobile={false}
                    setImage={setImageChange}
                    borderRight
                    title={data.titleLeft}
                    desc={data.descLeft}
                    btnDesc={data.btnLeft}
                    pathName={data.pathNameLeft}
                  />
                  <CardUp
                    key="1"
                    ismid
                    mobile={false}
                    borderRight
                    setImage={setImageChange}
                    title={data.titleMid}
                    desc={data.descMid}
                    btnDesc={data.btnMid}
                    pathName={data.pathNameMid}
                  />
                  <CardUp
                    key="2"
                    isRight
                    mobile={false}
                    setImage={setImageChange}
                    title={data.titleRight}
                    desc={data.descRight}
                    btnDesc={data.btnRight}
                    pathName={data.pathNameRight}
                  />
                </Content>
                <MyMotionImgLazzy title={image.title} src={image.src} height={"650px"} />
              </Hidden>
            </MyFadeUp>
          </ContentFade>
          <Hidden mdUp>
            <MyFlexCenter>
              <ContentFade>
                <MyFadeUp fade>
                  <ContentSm>
                    <CardUp
                      key="0"
                      isleft={true}
                      ismid={false}
                      isRight={false}
                      mobile
                      setImage={setImageChange}
                      title={data.titleLeft}
                      desc={data.descLeft}
                      btnDesc={data.btnLeft}
                      pathName={data.pathNameLeft}
                    />
                  </ContentSm>
                  <MyMotionImgLazzy title={data.image[0].title} src={data.image[0].src} height={"450px"} />
                </MyFadeUp>
              </ContentFade>
              <div style={{ padding: 10 }} />
              <ContentFade>
                <MyFadeUp fade>
                  <ContentSm>
                    <CardUp
                      key="1"
                      ismid={true}
                      isleft={false}
                      isRight={false}
                      mobile
                      setImage={setImageChange}
                      title={data.titleMid}
                      desc={data.descMid}
                      btnDesc={data.btnMid}
                      pathName={data.pathNameMid}
                    />
                  </ContentSm>
                  <MyMotionImgLazzy title={data.image[1].title} src={data.image[1].src} height={"450px"} />
                </MyFadeUp>
              </ContentFade>
              <div style={{ padding: 10 }} />
              <ContentFade>
                <MyFadeUp fade>
                  <ContentSm>
                    <CardUp
                      key="2"
                      isRight={true}
                      ismid={false}
                      isleft={false}
                      mobile
                      setImage={setImageChange}
                      title={data.titleRight}
                      desc={data.descRight}
                      btnDesc={data.btnRight}
                      pathName={data.pathNameRight}
                    />
                  </ContentSm>
                  <MyMotionImgLazzy title={data.image[2].title} src={data.image[2].src} height={"450px"} />
                </MyFadeUp>
              </ContentFade>
            </MyFlexCenter>
          </Hidden>
        </ContentSec>
      </ContentPrinc >
    );
    // }
  }

  return retornarComp();
}
