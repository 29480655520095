import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { IPatrocItem } from "./interface";
import { AspasComp, ContentDesc, ContentGrid, ContentLogo, ContentMaxWidth, ContentName, ContentPrinc, ContentSecLogo, ContentSocial, PaperComp } from "./styles";
import { transitionMotion } from "../../../Comum";
import { useAnimation } from "framer-motion";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import MyImageLazzy from "../../MyImageLazzy";
import MyLink from "../../MyLink";

export default function PatrocItem(props: IPatrocItem) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useHistory();
  const animationScale = useAnimation();

  const handleClick = async () => {
    await animationScale.start({ scale: 0.99 });
    await animationScale.start({ scale: 1 });
    navigate.push(props.page);
  }

  const aosDelay = () => {
    if (isSmDown) {
      return 0
    } else {
      return props.count === 0 ? 0 : 300 * props.count
    }
  }

  return (
    <Grid style={{ paddingBottom: isSmDown ? 35 : 0 }} key={props.count} item xs={12} sm={12} md={4} lg={4} xl={4}>
      <ContentGrid
        whileTap={{ scale: 0.99 }}
        animate={animationScale}
        onClick={handleClick}
        transition={transitionMotion}
      >
        <MyLink href={props.page}>
          <PaperComp
            bgColor={theme.palette.primary.main}
            data-aos="fade-up"
            data-aos-delay={aosDelay()}
          >
            <ContentPrinc>
              <Box >
                <ContentMaxWidth theme={theme} >
                  <AspasComp theme={theme} style={{ color: theme.palette.secondary.dark }} />
                  <ContentDesc children={
                    <MyTypographyDesc
                      fontFamily="regular"
                      component="p"
                      style={{ color: theme.palette.secondary.dark, letterSpacing: 1, opacity: .9 }}
                      fontxs={theme.typography.body1.fontSize.toString()}
                      fontsm={theme.typography.h6.fontSize.toString()}
                      fontmd={theme.typography.body1.fontSize.toString()}
                      fontlg={theme.typography.h6.fontSize.toString()}
                      fontxl={theme.typography.h6.fontSize.toString()}
                      children={props.desc}
                    />
                  }
                  />
                  <ContentName children={
                    <MyTypographyDesc
                      fontFamily="bold"
                      component="h3"
                      style={{ color: theme.palette.secondary.dark, opacity: .8, letterSpacing: 1.5 }}
                      fontxs={theme.typography.h6.fontSize.toString()}
                      fontsm={theme.typography.h5.fontSize.toString()}
                      fontmd={theme.typography.h6.fontSize.toString()}
                      fontlg={theme.typography.h5.fontSize.toString()}
                      fontxl={theme.typography.h5.fontSize.toString()}
                      children={props.name}
                    />
                  }
                  />
                  <ContentSocial children={
                    <MyTypographyDesc
                      fontFamily="light"
                      component="p"
                      style={{ color: theme.palette.secondary.dark, opacity: 0.8, letterSpacing: 1.3 }}
                      fontxs={theme.typography.body1.fontSize.toString()}
                      fontsm={theme.typography.body1.fontSize.toString()}
                      fontmd={theme.typography.body1.fontSize.toString()}
                      fontlg={theme.typography.body1.fontSize.toString()}
                      fontxl={theme.typography.body1.fontSize.toString()}
                      children={props.social}
                    />
                  }
                  />
                </ContentMaxWidth>
                <ContentLogo
                  children={
                    <ContentSecLogo
                      children={
                        <MyImageLazzy
                          title={props.name}
                          backgroundColor="transparent"
                          disableBackgroundLinear
                          image={props.logo}
                          scale={props.styleLogo.scale}
                          height={props.styleLogo.height + "px"}
                          width={`${props.styleLogo.width}px`}
                          borderRadius="0%"
                        />
                      }
                    />
                  }
                />
              </Box>
            </ContentPrinc>
          </PaperComp>
        </MyLink>
      </ContentGrid>
    </Grid>
  );
}
