import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import MyLottie from "../MyLottie";

import AnimationSec from "../../Assets/animation/animationSec.json";
import animationSecM1 from "../../Assets/animation/animationSecM1.json";
import animationSecM2 from "../../Assets/animation/animationSecM2.json";
import animationSecM3 from "../../Assets/animation/animationSecM3.json";
import descPrincNossoPrograma from "../../Assets/animation/descPrincNossoPrograma.json";
import MyFlexCenter from "../MyFlexCenter";
import { ContentBoxCardInformation, ContentCardInformation, ContentDescNossoPrograma, ContainerLottieM } from "./styles";
import { ItensLottieHtmlNossoProgramaHome } from "../LottieHtml/data";

export default function AnimationSecComp() {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <ContentDescNossoPrograma
                maxWidth="xs"
                children={
                    <MyLottie animation={descPrincNossoPrograma} scroll />
                }
            />
            <ItensLottieHtmlNossoProgramaHome />
            {isSm ?
                <ContainerLottieM theme={theme} maxWidth="sm" >
                    <ContentBoxCardInformation children={
                        <MyFlexCenter>
                            <MyLottie key={1} animation={animationSecM1} scroll />
                            <MyLottie key={2} animation={animationSecM2} scroll />
                            <MyLottie key={3} animation={animationSecM3} scroll />
                        </MyFlexCenter>
                    } />
                </ContainerLottieM>
                :
                <ContentCardInformation
                    maxWidth="lg"
                    children={
                        <MyLottie animation={AnimationSec} scroll />
                    }
                />

            }
        </>
    )
}