import { Box, useTheme, Link } from "@material-ui/core";
import { StickyContainer, Sticky } from "react-sticky";
import MyPressMotion from "../../MyPressMotion";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import MyImageLazzy from "../../MyImageLazzy";
import { CardActionAreaComp, ContentDescLeft, ContentDescLeftFlex, ContentImage, ContentTitle } from "./styles";
import MyFadeUp from "../../MyFadeUp";

export default function ImageTextSticky(props) {
  const theme = useTheme();

  return (
    <StickyContainer>
      <Sticky bottomOffset={-80}>
        {({
          style
        }) => (
          <header style={style}>
            <ContentDescLeftFlex>
              <ContentDescLeft>
                <Box
                  style={{ maxWidth: 650 }}
                >
                  {props.title &&
                    <ContentTitle>
                      <MyTypographyDesc
                        fade
                        fontFamily="semi-bold"
                        component="h2"
                        style={{ color: theme.palette.primary.light }}
                        fontxs={'1.90rem'}
                        fontsm={'1.90rem'}
                        fontmd={'1.90rem'}
                        fontlg={'1.90rem'}
                        fontxl={'1.90rem'}
                        children={props.title}
                      />
                    </ContentTitle>
                  }
                  <MyTypographyDesc
                    fade
                    fontFamily="light"
                    color="primary"
                    component="p"
                    theme={theme}
                    fontxs={theme.typography.h5.fontSize.toString()}
                    fontsm={theme.typography.h5.fontSize.toString()}
                    fontmd={theme.typography.h6.fontSize.toString()}
                    fontlg={theme.typography.h6.fontSize.toString()}
                    fontxl={theme.typography.h6.fontSize.toString()}
                    children={props.desc}
                  />
                </Box>
              </ContentDescLeft>
              <Box width="50%" />
            </ContentDescLeftFlex>
          </header>
        )}
      </Sticky>
      <ContentImage>
        <MyFadeUp fade>
          <CardActionAreaComp theme={theme} disableRipple >
            <MyPressMotion onClick={() => { }}>
              <Link target="_blank" href={"https://www.facebook.com/TVSudoesteParana/?locale=pt_BR"}  >
                <MyImageLazzy
                  title=""
                  backgrondLinear="rgb(0, 0, 0, 0), rgb(0 0 0 / 5%), rgb(0 0 0 / 10%), rgb(0 0 0 / 15%), rgb(0, 0, 0, 20%))"
                  borderRadius={9}
                  image={props.img}
                  height='800px'
                  width='44vw'
                />
              </Link>
            </MyPressMotion>
          </CardActionAreaComp>
        </MyFadeUp>
      </ContentImage>
    </StickyContainer>
  );
}
