import React from "react";
import { motion, useAnimation } from "framer-motion";
import { IMyPressMotion } from "./interface";

export default function MyPressMotion({ children, onClick }: IMyPressMotion) {
    const animationScale = useAnimation();

    const hanldeClick = async () => animationScale.start({ scale: 0.99 }).then(() => animationScale.start({ scale: 1 }).then(() => onClick()));

    return (
        <motion.div
            style={{ height: "100%", width: "100%", cursor: 'pointer' }}
            whileTap={{ scale: 0.99 }}
            onClick={hanldeClick}
            children={children}
            transition={{
                ease: [.6, .6, .6, .6],
                duration: .1
            }}
        />
    )
}