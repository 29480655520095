const logoVitralSul = "https://d13x8u4oijb9vw.cloudfront.net/vitral-logo.png";

export const IconeCresol = "https://d13x8u4oijb9vw.cloudfront.net/cresol-logo.png";

export const logoCresolVertical150 = "https://d13x8u4oijb9vw.cloudfront.net/cresol-vertical-logo-150x150.png";

export const logoCresolVertical200 = "https://d13x8u4oijb9vw.cloudfront.net/cresol-vertical-logo-200x200.png";

const descCresol = "Fornecendo soluções financeiras com excelência por meio do relacionamento.";

const descCresolTradicao = "Instituição Financeira Cooperativa. Fornecendo soluções financeiras com excelência por meio do relacionamento."

const C_DESC_VITRAL_SUL = "Atuamos no ramo vidreiro há 26 anos no Paraná, Santa Catarina e Rio Grande do Sul."

export default [
    {
        master: false,
        logo: IconeCresol,
        desc: descCresolTradicao,
        name: "Cresol Tradição",
        social: "@cresoltradicao",
        page: "/patrocinadores/cresol-tradicao",
        styleLogo: {
            height: 130,
            width: 130,
            scale: 0.7
        }
    },
    {
        master: true,
        logo: logoCresolVertical200,
        desc: descCresol,
        name: "Cresol",
        social: "@ccresol.coop",
        page: "patrocinadores/cresol",
        styleLogo: {
            height: 180,
            width: 180,
            scale: 1
        }
    },
    {
        master: false,
        logo: logoVitralSul,
        desc: C_DESC_VITRAL_SUL,
        name: "Vitral Sul",
        social: "@vitralsul",
        page: "patrocinadores/vitral-sul",
        styleLogo: {
            height: 130,
            width: 130,
            scale: 1,
        }
    }
];
