import styled from "styled-components";
import { motion } from "framer-motion";
import { IContentShadow, IContentImg } from "./interface";

export const ContentShadow = styled(motion.div)<IContentShadow>(({ theme, height }) => ({
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 25%), rgb(0 0 0 / 50%), rgb(0 0 0 / 55%), rgba(0, 0, 0, 0.6))`,
    width: "100%",
    height: height ? height : "100%",
    marginTop: '-' + height,
    borderRadius: 9
}));


export const ContentImg = styled(motion.div)<IContentImg>(({ theme, height, img }) => ({
    width: "100%",
    height: height ? height : "100%",
    marginTop: '-' + height,
    borderRadius: 9,
    border: 0,
    backgroundImage: `url(${img})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}));

export const Content = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 9
}));
