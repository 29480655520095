import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { ITypographyDesc, typeDesc } from "./interface";
import { fontMazzartB, fontMazzartLight, fontMazzartR, fontMazzartSemiBold, fontMazzartMediumL, fontMazzartLBold } from "../../../theme";

export const TypographyDesc = styled(Typography)<ITypographyDesc>(({ theme, fontFamily, fontlg, fontmd, fontsm, fontxl, fontxs }) => ({
    fontFamily: returnFontFamily(fontFamily),
    [theme.breakpoints.down('xs')]: {
        fontSize: retornFontSize(fontxs)
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: retornFontSize(fontsm)
    },
    [theme.breakpoints.up('md')]: {
        fontSize: retornFontSize(fontmd)
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: retornFontSize(fontlg)
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: retornFontSize(fontxl)
    }
}));

function retornFontSize(AFontSize: string | undefined): string {
    if (AFontSize === undefined) {
        return '10px'
    } else {
        return AFontSize;
    }
}

function returnFontFamily(AFontFamilyT: typeDesc): string {
    switch (AFontFamilyT) {
        case "bold":
            return fontMazzartB;
        case "light":
            return fontMazzartLight;
        case "regular":
            return fontMazzartR;
        case "semi-bold":
            return fontMazzartSemiBold;
        case "medium-l":
            return fontMazzartMediumL;
        case "l-bold":
            return fontMazzartLBold;
    }
}