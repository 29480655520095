import { Box, Container, useTheme } from "@material-ui/core";
import Patroc from "../../Components/Patroc";
import CardHover from "../CardHover";
import Opinion from "../Opinion";
import MyLottie from "../MyLottie";
import mainAnimation from "../../Assets/animation/mainAnimation.json";
import mainAnimationM from "../../Assets/animation/mainAnimationM.json";
import descPrincEquipe from "../../Assets/animation/descPrincEquipe.json";
import descPrincColab from "../../Assets/animation/descPrincColab.json";
import descPrincOpiniao from "../../Assets/animation/descPrincOpiniao.json";
import AnimationSecComp from "../AnimationSec";
import MyAnimTextMain from "../MyAnimTextMain";
import { ContentCardHover, ContentOpinion, ContentPatroc, ContentValues } from "./styles";
import CardInformation from "../CardInformation";
import { ItensLottieHtmlDescPrincHome, ItensLottieHtmlDescOpiniaoHome, ItensLottieHtmlDescPrincColabHome } from "../LottieHtml/data";

export const imagemMain = "https://d13x8u4oijb9vw.cloudfront.net/Main-img.jpg";

export default function Principal() {
  const theme = useTheme();

  return (
    <main style={{ backgroundColor: theme.palette.background.default }} >

      <section>
        <MyAnimTextMain title="" isMovie anim={mainAnimation} animM={mainAnimationM} img={imagemMain} />
      </section>

      <section>
        <AnimationSecComp />
      </section>

      <section>
        <Container style={{ paddingBottom: 40 }} maxWidth="xs" children={
          <>
            <ItensLottieHtmlDescPrincHome />
            <MyLottie animation={descPrincEquipe} scroll />
          </>
        } />

        <ContentCardHover maxWidth="lg" children={<CardHover />} />
      </section>

      <section>
        <Container style={{ paddingBottom: 30 }} maxWidth="xs" children={
          <>
            <ItensLottieHtmlDescPrincColabHome />
            <MyLottie animation={descPrincColab} scroll />
          </>
        } />
        <ContentPatroc maxWidth="lg" children={<Patroc />} />
      </section>

      <section>
        <ContentValues theme={theme} maxWidth={"xl"} children={<CardInformation />} />
      </section>

      <section>
        <Container style={{ paddingBottom: 40 }} maxWidth="xs" children={
          <>
            <ItensLottieHtmlDescOpiniaoHome />
            <MyLottie animation={descPrincOpiniao} scroll />
          </>
        } />
        <ContentOpinion maxWidth={"lg"} children={<Opinion />} />
      </section>
    </main>
  );
}
