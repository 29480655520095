import React from "react";
import CrSelMaterial from "./CrSelMaterial";
import { ContentPrinc } from "./styles";

export default function Opinion() {
  return (
    <ContentPrinc>
      <CrSelMaterial />
    </ContentPrinc>
  );
}
