import { Container } from "@material-ui/core";
import { motion } from "framer-motion";
import styled from "styled-components";

export const ContentPrincTimeLine = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

export const ContentSecTimeLine = styled(motion.div)`
`;

export const ContentCardHover = styled(Container)`
  padding-bottom: 160px;
`;

export const ContentPatroc = styled(Container)`
  padding-bottom: 160px;
`;

export const ContentValues = styled(Container)(({ theme })=>({
  paddingBottom: 160,
  [theme.breakpoints.down('xs')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export const ContentOpinion = styled(Container)`
  padding-bottom: 60px;
`;

