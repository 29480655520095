import styled from "styled-components";
import { IBgImage } from "./interface";

const transition = ".3s";

export const transitionTimingF = "cubic-bezier(.6, .6, .6, .6)";

export const BoxPrinc = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: inherit;
`;

export const BgImage = styled.div<IBgImage>`
  height: ${(props) => props.height ? props.height : '100vh'};
  width: 100%;
  transition: ${transition};
  transition-timing-function: ${transitionTimingF};
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 20%), rgb(0 0 0 / 38%), rgb(0 0 0 / 47%), rgba(0, 0, 0, 0.6)), url(${(props) => props.img});  
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  /* filter: brightness(85%); */
  /* filter: blur(1.5px); */
  /* transform: scale(${(props) => props.scale}); */
  animation: slidein 7s;
  @keyframes slidein {
  from {
    transform: scale(${(props) => props.scale});
    /* filter: blur(0); */
  }
  to {
    transform: scale(1);
    /* filter: blur(1.5px); */
  }
}  
`;

export const ContentLottie = styled.div(({ }) => ({
  height: '100vh',
  position: "absolute",
  top: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 3
}));

export const ContentNoMaxWidth = styled.div(({ }) => ({
  width: "100%",
  maxWidth: 600
}));

export const ContentMaxWidth = styled.div(({ }) => ({
  height: "100%",
  width: "100%",
  maxWidth: 1100,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}));

export const ContentBgColor = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.background.default
}));

